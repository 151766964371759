const UserGroup = () => {
  return (
    <svg
      width="15"
      height="12"
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.1673 11.25V10.0833C14.1673 8.79467 13.1226 7.75 11.834 7.75H11.5423M9.50065 5.41667C10.7893 5.41667 11.834 4.372 11.834 3.08333C11.834 1.79467 10.7893 0.75 9.50065 0.75M10.6673 11.25V10.0833C10.6673 8.79467 9.62265 7.75 8.33398 7.75H3.66732C2.37865 7.75 1.33398 8.79467 1.33398 10.0833V11.25M8.33398 3.08333C8.33398 4.372 7.28932 5.41667 6.00065 5.41667C4.71199 5.41667 3.66732 4.372 3.66732 3.08333C3.66732 1.79467 4.71199 0.75 6.00065 0.75C7.28932 0.75 8.33398 1.79467 8.33398 3.08333Z"
        stroke="#666687"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default UserGroup;
