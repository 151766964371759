import apiClient from 'utilities/apiClient';

export const approvers = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.approvers}`, payload, true);
};

export const create_envelop = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.create_envelop}`,
    payload,
    true,
    'file'
  );
};

export const create_standard_envelop = (url, payload) => {
  return apiClient.post(
    // `${apiClient.Urls.admin}/${url}`,
    `${apiClient.Urls.get_templates}/${url}`,
    payload,
    true,
    'file'
  );
};

export const get_AllStandardEnvelopes = (url, payload) => {
  return apiClient.get(
    // `${apiClient.Urls.all_standard_envelopes}/${url}`,
    `${apiClient.Urls.get_templates}/${url}`,
    payload,
    true
  );
};

export const projects = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.admin}/${url}`, payload, true);
};

export const customenv = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.all_standard_envelopes}/${url}`,
    payload,
    true
  );
};

export const allenv = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.all_standard_envelopes}/${url}`,
    payload,
    true
  );
};

export const unit = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.unit}/${url}`, payload, true);
};

export const price = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.price}/${url}`, payload, true);
};

export const categories = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.categories}/${url}`, payload, true);
};

export const subcategories = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.subcategories}/${url}`, payload, true);
};

export const getlocationss = (payload) => {
  return apiClient.get(`${apiClient.Urls.locations}`, payload, true);
};

export const get_functions = (payload) => {
  return apiClient.get(`${apiClient.Urls.functions}`, payload, true);
};

export const getplantss = (payload) => {
  return apiClient.get(`${apiClient.Urls.plants}`, payload, true);
};

export const get_category = (payload) => {
  return apiClient.get(`${apiClient.Urls.categorylist}`, payload, true);
};

export const get_subcategory = (url, payload) => {
  // console.log('payload', url);
  return apiClient.get(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};

export const get_activity = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
