import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  SBU_ById,
  BU_ById,
  allApprovers,
  allDesignations,
  allDesignationsMappings,
  allDoaFlows,
  allProjects,
  allSBUs,
  allBUs,
  allSBGs,
  allSubCategories,
  approver_ById,
  categories_ById,
  create_Approvers,
  create_Categories,
  create_DOA_Flow,
  create_DesignationMappings,
  create_Designations,
  create_Projects,
  create_SBUs,
  create_SubCategories,
  designationMappings_ById,
  designation_ById,
  project_ById,
  subcategories_ById,
  create_BUs,
  SBG_ById,
  create_SBGs,
  search_Specifcity,
  filter_Designations
} from './api';

const initialState = {
  byIdLoading: false,
  all_Doa_List: [],
  all_Projects: [],
  projectsLoading: false,
  all_SBUs: [],
  all_BUs: [],
  all_SBGs: [],
  all_approvers: [],
  all_designations: [],
  all_designationMappings: [],
  approverById: {},
  projectById: {},
  designationById: {},
  designationMappingById: {},
  designationMappingByIdLoading: false,
  sbuById: {},
  buById: {},
  category_by_id: {},
  all_subcategories: [],
  subcategory_by_id: {},
  tableLoading: false,
  pagination: null,
  sbu_pagination: null,
  designation_pagination: null,
  bu_pagination: null,
  sbg_pagination: null,
  designationLoading: false,
  specificitySearchData: [],
  filteredResults: [],
  filteredDataLoading: false,
  filteredPagination: null
};

const actions = {
  GETALLAPROVERSLIST: 'getAllApproversList/GETALLAPROVERSLIST',
  GETALLDOALIST: 'getAllDoaList/GETALLDOALIST',
  GETALLPROJECTS: 'getAllProjects/GETALLPROJECTS',
  GETALLSBUS: 'getAllSbus/GETALLSBUS',
  GETALLSUBCATGEORIES: 'getAllSubCategories/GETALLSUBCATGEORIES',
  GET_ALL_BUS: 'getAllbus/GET_ALL_BUS',
  GET_ALL_SBGS: 'getAllSbgs/GET_ALL_SBGS',
  GETAPROVERBYID: 'getApproverById/GETAPROVERBYID',
  GETDESIGNATIONBYID: 'getDesignationById/GETDESIGNATIONBYID',
  GETPROJECTBYID: 'getProjectById/GETPROJECTBYID',
  GETALLDESIGNATIONSLIST: 'getAllDesignationsList/GETALLDESIGNATIONSLIST',
  GETSBUBYID: 'getSbuById/GETSBUBYID',
  GET_SBU_BYID: 'getSbuById/GET_SBU_BYID',
  GET_SBG_BYID: 'getSbgById/GET_SBG_BYID',
  GET_BU_BYID: 'getBuById/GET_BU_BYID',
  CREATESBU: 'createSbu/CREATESBU',
  CREATESBG: 'createSbg/CREATESBG',
  CREATEBU: 'createBu/CREATEBU',

  GETCATEGORYBYID: 'getCategoryById/GETCATEGORYBYID',
  GETSUBCATEGORYBYID: 'getSubCategoryById/GETSUBCATEGORYBYID',

  CREATEAPPROVERS: 'createApprovers/CREATEAPPROVERS',
  CREATEDESIGNATIONS: 'createDesignations/CREATEDESIGNATIONS',
  GETDESIGNATIONMAPPINGBYID:
    'getDesignationMappingById/GETDESIGNATIONMAPPINGBYID',
  GETALLDESIGNATIONMAPPINGSLIST:
    'getAllDesignationMappingsList/GETALLDESIGNATIONMAPPINGSLIST',
  GETALLSPECIFICITYDATA: 'getAllSpecificityData/GETALLSPECIFICITYDATA',
  GETFILTEREDRESULTS: 'getFilteredResults/GETFILTEREDRESULTS',
  CREATEDESIGNATIONMAPPINGS:
    'createDesignationMappings/CREATEDESIGNATIONMAPPINGS',

  CREATECATEGORY: 'createCategory/CREATECATEGORY',
  CREATESUBCATEGORY: 'createSubCategory/CREATESUBCATEGORY',
  CREATEDOAFLOW: 'createDoaFlow/CREATEDOAFLOW',
  CREATEPROJECTS: 'createProjects/CREATEPROJECTS'
};

export const getAllApproversList = createAsyncThunk(
  actions.GETALLAPROVERSLIST,
  async (payload) => {
    const response = await allApprovers(payload);
    return response;
  }
);

export const getAllDoaList = createAsyncThunk(
  actions.GETALLDOALIST,
  async (payload) => {
    const response = await allDoaFlows(payload);
    return response;
  }
);

export const getAllSbus = createAsyncThunk(
  actions.GETALLSBUS,
  async (payload) => {
    const response = await allSBUs(payload);
    return response;
  }
);

export const getAllbus = createAsyncThunk(
  actions.GET_ALL_BUS,
  async (payload) => {
    const response = await allBUs(payload);
    return response;
  }
);
export const getAllSbgs = createAsyncThunk(
  actions.GET_ALL_SBGS,
  async (payload) => {
    const response = await allSBGs(payload);
    return response;
  }
);

export const getAllSubCategories = createAsyncThunk(
  actions.GETALLSUBCATGEORIES,
  async (payload) => {
    const response = await allSubCategories(payload);
    return response;
  }
);

export const getAllProjects = createAsyncThunk(
  actions.GETALLPROJECTS,
  async (payload) => {
    const response = await allProjects(payload);
    return response;
  }
);

export const getProjectById = createAsyncThunk(
  actions.GETPROJECTBYID,
  async (payload) => {
    const response = await project_ById(payload);
    return response;
  }
);

export const getApproverById = createAsyncThunk(
  actions.GETAPROVERBYID,
  async (payload) => {
    const response = await approver_ById(payload);
    return response;
  }
);

export const getDesignationById = createAsyncThunk(
  actions.GETDESIGNATIONBYID,
  async (payload) => {
    const response = await designation_ById(payload);
    return response;
  }
);

export const getSbuById = createAsyncThunk(
  actions.GETSBUBYID,
  async (payload) => {
    const response = await SBU_ById(payload);
    return response;
  }
);

export const getSbgById = createAsyncThunk(
  actions.GET_SBG_BYID,
  async (payload) => {
    const response = await SBG_ById(payload);
    return response;
  }
);

export const getCategoryById = createAsyncThunk(
  actions.GETCATEGORYBYID,
  async (payload) => {
    const response = await categories_ById(payload);
    return response;
  }
);

export const getBuById = createAsyncThunk(
  actions.GET_BU_BYID,
  async (payload) => {
    const response = await BU_ById(payload);
    return response;
  }
);

export const getSubCategoryById = createAsyncThunk(
  actions.GETSUBCATEGORYBYID,
  async (payload) => {
    const response = await subcategories_ById(payload);
    return response;
  }
);

export const getAllDesignationsList = createAsyncThunk(
  actions.GETALLDESIGNATIONSLIST,
  async (payload) => {
    const response = await allDesignations(payload);
    return response;
  }
);

export const getDesignationMappingById = createAsyncThunk(
  actions.GETDESIGNATIONMAPPINGBYID,
  async (payload) => {
    const response = await designationMappings_ById(payload);
    return response;
  }
);

export const getAllDesignationMappingsList = createAsyncThunk(
  actions.GETALLDESIGNATIONMAPPINGSLIST,
  async (payload) => {
    const response = await allDesignationsMappings(payload);
    return response;
  }
);

export const getAllSpecificityData = createAsyncThunk(
  actions.GETALLSPECIFICITYDATA,
  async (payload) => {
    const response = await search_Specifcity(payload);
    return response;
  }
);

export const getFilteredResults = createAsyncThunk(
  actions.GETFILTEREDRESULTS,
  async (payload) => {
    const response = await filter_Designations(payload);
    return response;
  }
);

export const createApprovers = createAsyncThunk(
  actions.CREATEAPPROVERS,
  async (payload) => {
    const response = await create_Approvers(payload);
    return response;
  }
);

export const createDesignations = createAsyncThunk(
  actions.CREATEDESIGNATIONS,
  async (payload) => {
    const response = await create_Designations(payload);
    return response;
  }
);

export const createDesignationMappings = createAsyncThunk(
  actions.CREATEDESIGNATIONMAPPINGS,
  async (payload) => {
    const response = await create_DesignationMappings(payload);
    return response;
  }
);

export const createProjects = createAsyncThunk(
  actions.CREATEPROJECTS,
  async (payload) => {
    const response = await create_Projects(payload);
    return response;
  }
);

export const createDoaFlow = createAsyncThunk(
  actions.CREATEDOAFLOW,
  async (payload) => {
    const response = await create_DOA_Flow(payload);
    return response;
  }
);

export const createSbu = createAsyncThunk(
  actions.CREATESBU,
  async (payload) => {
    const response = await create_SBUs(payload);
    return response;
  }
);

export const createCategory = createAsyncThunk(
  actions.CREATECATEGORY,
  async (payload) => {
    const response = await create_Categories(payload);
    return response;
  }
);

export const createSubCategory = createAsyncThunk(
  actions.CREATESUBCATEGORY,
  async (payload) => {
    const response = await create_SubCategories(payload);
    return response;
  }
);
export const createSbg = createAsyncThunk(
  actions.CREATESBG,
  async (payload) => {
    const response = await create_SBGs(payload);
    return response;
  }
);

export const createBu = createAsyncThunk(actions.CREATEBU, async (payload) => {
  const response = await create_BUs(payload);
  return response;
});

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllApproversList.pending, (state) => {
        state.tableLoading = true;
      })
      .addCase(getAllApproversList.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.all_approvers = data.data;

        state.tableLoading = false;
      })
      .addCase(getAllApproversList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.tableLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllDoaList.pending, (state) => {
        state.tableLoading = true;
      })
      .addCase(getAllDoaList.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.all_Doa_List = data.data;
        state.pagination = data.pagination || null;
        state.tableLoading = false;
      })
      .addCase(getAllDoaList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.tableLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllSbus.pending, (state) => {
        state.tableLoading = true;
      })
      .addCase(getAllSbus.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;

        state.all_SBUs = data;
        state.tableLoading = false;
        state.sbu_pagination = pagination || null;
      })
      .addCase(getAllSbus.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.tableLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllSbgs.pending, (state) => {
        state.tableLoading = true;
      })
      .addCase(getAllSbgs.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.tableLoading = false;
        state.all_SBGs = data;
        state.sbg_pagination = pagination;
      })
      .addCase(getAllSbgs.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.tableLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllbus.pending, (state) => {
        state.tableLoading = true;
      })
      .addCase(getAllbus.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.tableLoading = false;
        state.all_BUs = data;
        state.bu_pagination = pagination;
      })
      .addCase(getAllbus.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.tableLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllSubCategories.pending, (state) => {
        state.tableLoading = true;
      })
      .addCase(getAllSubCategories.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;

        state.all_subcategories = data;
        state.tableLoading = false;
        state.sbu_pagination = pagination || null;
      })
      .addCase(getAllSubCategories.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.tableLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getApproverById.pending, (state) => {
        state.byIdLoading = true;
      })
      .addCase(getApproverById.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.approverById = data;
        state.byIdLoading = false;
      })
      .addCase(getApproverById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.byIdLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getSbuById.pending, (state) => {
        state.byIdLoading = true;
      })
      .addCase(getSbuById.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.sbuById = data;
        state.byIdLoading = false;
      })
      .addCase(getSbuById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.byIdLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getSbgById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSbgById.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.sbgById = data;
      })
      .addCase(getSbgById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getCategoryById.pending, (state) => {
        state.byIdLoading = true;
      })
      .addCase(getCategoryById.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.category_by_id = data;
        state.byIdLoading = false;
      })
      .addCase(getCategoryById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.byIdLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getSubCategoryById.pending, (state) => {
        state.byIdLoading = true;
      })
      .addCase(getSubCategoryById.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.subcategory_by_id = data;
        state.byIdLoading = false;
      })
      .addCase(getSubCategoryById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.byIdLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getBuById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBuById.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.buById = data;
      })
      .addCase(getBuById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getProjectById.pending, (state) => {
        state.byIdLoading = true;
      })
      .addCase(getProjectById.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.projectById = data;
        state.byIdLoading = false;
      })
      .addCase(getProjectById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.byIdLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getDesignationById.pending, (state) => {
        state.byIdLoading = true;
      })
      .addCase(getDesignationById.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.designationById = data;
        state.byIdLoading = false;
      })
      .addCase(getDesignationById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.byIdLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllProjects.pending, (state) => {
        state.projectsLoading = true;
      })
      .addCase(getAllProjects.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;

        state.all_Projects = data;
        state.projectsLoading = false;
        state.pagination = pagination || null;
      })
      .addCase(getAllProjects.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.projectsLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getDesignationMappingById.pending, (state) => {
        state.designationMappingByIdLoading = true;
      })
      .addCase(getDesignationMappingById.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.designationMappingByIdLoading = false;
        state.designationMappingById = data;
      })
      .addCase(getDesignationMappingById.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.designationMappingByIdLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllDesignationsList.pending, (state) => {
        state.designationLoading = true;
      })
      .addCase(getAllDesignationsList.fulfilled, (state, action) => {
        const { data } = action.payload;
        console.log('AAD', data);
        state.sbu_pagination = data.pagination || null;
        state.all_designations = data.rows;

        state.designationLoading = false;
      })
      .addCase(getAllDesignationsList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.designationLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllSpecificityData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSpecificityData.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.specificitySearchData = data;
        state.loading = false;
      })
      .addCase(getAllSpecificityData.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });
    builder
      .addCase(getFilteredResults.pending, (state) => {
        state.filteredDataLoading = true;
      })
      .addCase(getFilteredResults.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.filteredResults = data;
        state.filteredDataLoading = false;
        state.filteredPagination = pagination || null;
      })
      .addCase(getFilteredResults.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.filteredDataLoading = false;
        message.error(msg);
      });
    builder
      .addCase(getAllDesignationMappingsList.pending, (state) => {
        state.tableLoading = true;
      })
      .addCase(getAllDesignationMappingsList.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.all_designationMappings = data.data;
        state.designation_pagination = data.pagination || null;
        state.tableLoading = false;
      })
      .addCase(getAllDesignationMappingsList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.tableLoading = false;

        message.error(msg);
      });
    builder
      .addCase(createApprovers.pending, (state) => {
        state.loading = true;
      })
      .addCase(createApprovers.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createApprovers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(createDesignations.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDesignations.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createDesignations.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(createDesignationMappings.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDesignationMappings.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createDesignationMappings.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(createProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(createProjects.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createProjects.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCategory.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createCategory.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createSubCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSubCategory.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createSubCategory.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createDoaFlow.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDoaFlow.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createDoaFlow.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createSbu.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSbu.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createSbu.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(createBu.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBu.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createBu.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

/* export const getTrackersActions = settingsSlice.actions; */
/* export const { resetTrackers } = settingsSlice.actions; */

export default settingsSlice.reducer;
