import React from 'react';
import { Modal } from 'antd';
import CustomTable from 'common/Content/CustomTable';

const FormatDocs = ({ formatModal, setFormatModal, customenv }) => {
  const columns = [
    {
      title: 'Document name',
      dataIndex: 'file_name',
      key: 'file_name'
    },
    {
      title: 'Download',
      dataIndex: 'url',
      key: 'url',
      render: (record) => (
        <a href={record} download target="__blank">
          {'Download'}
        </a>
      )
    }
  ];
  return (
    <Modal
      title="Standard Formats"
      visible={formatModal}
      //   onOk={handleOk}
      onCancel={() => setFormatModal(false)}
    >
      <CustomTable data={customenv.Documents} columns={columns} />
    </Modal>
  );
};

export default FormatDocs;
