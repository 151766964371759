import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  SBU_ById,
  BU_ById,
  allApprovers,
  allDesignations,
  allDesignationsMappings,
  allDoaFlows,
  allProjects,
  allSBUs,
  allBUs,
  allSBGs,
  // allSubCategories,
  approver_ById,
  create_Approvers,
  create_DOA_Flow,
  create_DesignationMappings,
  create_Designations,
  create_Projects,
  create_SBUs,
  // create_SubCategories,
  designationMappings_ById,
  designation_ById,
  project_ById,
  create_BUs,
  SBG_ById,
  create_SBGs,
  search_Specifcity,
  filter_Designations,
  // // // //
  get_category,
  get_subcategory,
  getplantss,
  getdepartments,
  get_activity,
  allUsers,
  get_plant_id,
  edit_plant_id,
  create_plant,
  create_department,
  edit_department,
  get_department_details,
  get_activity_id,
  edit_activity,
  create_activity,
  categories_ById,
  edit_categories,
  create_Categories,
  subcategories_ById,
  edit_SubCategories,
  create_SubCategories,
  userDetails_ById,
  edit_userdetails,
  create_userdetails
} from './api';

const initialState = {
  byIdLoading: false,
  all_Doa_List: [],
  all_Projects: [],
  projectsLoading: false,
  all_SBUs: [],
  all_BUs: [],
  all_SBGs: [],
  all_approvers: [],
  all_designations: [],
  all_designationMappings: [],
  approverById: {},
  projectById: {},
  designationById: {},
  designationMappingById: {},
  designationMappingByIdLoading: false,
  sbuById: {},
  buById: {},
  all_subcategories: [],
  tableLoading: false,
  pagination: null,
  sbu_pagination: null,
  designation_pagination: null,
  bu_pagination: null,
  sbg_pagination: null,
  designationLoading: false,
  specificitySearchData: [],
  filteredResults: [],
  filteredDataLoading: false,
  filteredPagination: null,
  // // // //
  categories: [],
  get_subcategorylist: [],
  get_plants: [],
  get_departments: [],
  get_activity: [],
  all_Users: [],
  get_plant: [],
  plantDetails: [],
  createplantDetails: [],
  createDepartmentDetails: [],
  editDepartmentDetails: [],
  getDepartmentDetails: [],
  getActivityDetails: [],
  editActivityDetails: [],
  createActivityDetails: [],
  category_by_id: [],
  editCategoryDetails: [],
  subcategory_by_id: [],
  getuserDetails: []
};

const actions = {
  GETALLAPROVERSLIST: 'getAllApproversList/GETALLAPROVERSLIST',
  GETALLDOALIST: 'getAllDoaList/GETALLDOALIST',
  GETALLPROJECTS: 'getAllProjects/GETALLPROJECTS',
  GETALLSBUS: 'getAllSbus/GETALLSBUS',
  GETALLSUBCATGEORIES: 'getAllSubCategories/GETALLSUBCATGEORIES',
  GET_ALL_BUS: 'getAllbus/GET_ALL_BUS',
  GET_ALL_SBGS: 'getAllSbgs/GET_ALL_SBGS',
  GETAPROVERBYID: 'getApproverById/GETAPROVERBYID',
  GETDESIGNATIONBYID: 'getDesignationById/GETDESIGNATIONBYID',
  GETPROJECTBYID: 'getProjectById/GETPROJECTBYID',
  GETALLDESIGNATIONSLIST: 'getAllDesignationsList/GETALLDESIGNATIONSLIST',
  GETSBUBYID: 'getSbuById/GETSBUBYID',
  GET_SBU_BYID: 'getSbuById/GET_SBU_BYID',
  GET_SBG_BYID: 'getSbgById/GET_SBG_BYID',
  GET_BU_BYID: 'getBuById/GET_BU_BYID',
  CREATESBU: 'createSbu/CREATESBU',
  CREATESBG: 'createSbg/CREATESBG',
  CREATEBU: 'createBu/CREATEBU',

  CREATEAPPROVERS: 'createApprovers/CREATEAPPROVERS',
  CREATEDESIGNATIONS: 'createDesignations/CREATEDESIGNATIONS',
  GETDESIGNATIONMAPPINGBYID:
    'getDesignationMappingById/GETDESIGNATIONMAPPINGBYID',
  GETALLDESIGNATIONMAPPINGSLIST:
    'getAllDesignationMappingsList/GETALLDESIGNATIONMAPPINGSLIST',
  GETALLSPECIFICITYDATA: 'getAllSpecificityData/GETALLSPECIFICITYDATA',
  GETFILTEREDRESULTS: 'getFilteredResults/GETFILTEREDRESULTS',
  CREATEDESIGNATIONMAPPINGS:
    'createDesignationMappings/CREATEDESIGNATIONMAPPINGS',

  CREATEDOAFLOW: 'createDoaFlow/CREATEDOAFLOW',
  CREATEPROJECTS: 'createProjects/CREATEPROJECTS',
  //
  GETALLCATGEORIES: 'getAllCategories/GETALLCATGEORIES',
  GETALLPLANTS: 'getAllPlants/GETALLPLANTS',
  GETALLDEPARTMENTS: 'getAllDepartments/GETALLDEPARTMENTS',
  GETALLACTIVITY: 'getAllActivity/GETALLACTIVITY',
  GET_ALL_USERS: 'getAllUsers/GET_ALL_USERS',
  GETPLANTID: 'getPlantId/GETPLANTID',
  EDITPLANT: 'editPlant/EDITPLANT',
  CREATEPLANT: 'createPlant/CREATEPLANT',
  CREATEDEPARTMENT: 'createDepartment/CREATEDEPARTMENT',
  EDITDEPARTMENT: 'editDepartment/EDITDEPARTMENT',
  GETDEPARTMENTBYID: 'getDepartmentById/GETDEPARTMENTBYID',
  GETACTIVITYID: 'getActivityId/GETACTIVITYID',
  EDITACTIVITY: 'editActivity/EDITACTIVITY',
  CREATEACTIVITY: 'createActivity/CREATEACTIVITY',
  EDITCATEGORY: 'editCategory/EDITCATEGORY',
  CREATECATEGORY: 'createCategory/CREATECATEGORY',
  GETCATEGORYBYID: 'getCategoryById/GETCATEGORYBYID',
  GETSUBCATEGORYBYID: 'getSubCategoryById/GETSUBCATEGORYBYID',
  EDITSUBCATEGORY: 'editSubCategory/EDITSUBCATEGORY',
  CREATESUBCATEGORY: 'createSubCategory/CREATESUBCATEGORY',
  GET_USERDETAILS_BYID: 'get_userDetails_ById/GET_USERDETAILS_BYID',
  EDITUSERDETAILS: 'editUserDetails/EDITUSERDETAILS',
  CREATEUSERINFO: 'createUserInfo/CREATEUSERINFO'
};

export const getAllApproversList = createAsyncThunk(
  actions.GETALLAPROVERSLIST,
  async (payload) => {
    const response = await allApprovers(payload);
    return response;
  }
);

export const getAllDoaList = createAsyncThunk(
  actions.GETALLDOALIST,
  async (payload) => {
    const response = await allDoaFlows(`get-all-templates`, payload);
    return response;
  }
);

export const getAllSbus = createAsyncThunk(
  actions.GETALLSBUS,
  async (payload) => {
    const response = await allSBUs(payload);
    return response;
  }
);

export const getAllbus = createAsyncThunk(
  actions.GET_ALL_BUS,
  async (payload) => {
    const response = await allBUs(payload);
    return response;
  }
);
// getAllSbgs
export const getAllSbgs = createAsyncThunk(
  actions.GET_ALL_SBGS,
  async (payload) => {
    const response = await allSBGs(payload);
    return response;
  }
);

// Get All Users
export const getAllUsers = createAsyncThunk(
  actions.GET_ALL_USERS,
  async (payload) => {
    const response = await allUsers(`get-all-users`, payload);
    return response;
  }
);
// getSubCategory
export const getAllSubCategories = createAsyncThunk(
  actions.GETALLSUBCATGEORIES,
  async (payload) => {
    const response = await get_subcategory(`get-all-sub-categories`, payload);
    return response;
  }
);
// getAllCategories
export const getAllCategories = createAsyncThunk(
  actions.GETALLCATGEORIES,
  async (payload) => {
    const response = await get_category(payload);
    return response;
  }
);

export const getAllProjects = createAsyncThunk(
  actions.GETALLPROJECTS,
  async (payload) => {
    const response = await allProjects(payload);
    return response;
  }
);

export const getProjectById = createAsyncThunk(
  actions.GETPROJECTBYID,
  async (payload) => {
    const response = await project_ById(payload);
    return response;
  }
);

export const getApproverById = createAsyncThunk(
  actions.GETAPROVERBYID,
  async (payload) => {
    const response = await approver_ById(payload);
    return response;
  }
);

export const getDesignationById = createAsyncThunk(
  actions.GETDESIGNATIONBYID,
  async (payload) => {
    const response = await designation_ById(payload);
    return response;
  }
);

export const getSbuById = createAsyncThunk(
  actions.GETSBUBYID,
  async (payload) => {
    const response = await SBU_ById(payload);
    return response;
  }
);

export const getSbgById = createAsyncThunk(
  actions.GET_SBG_BYID,
  async (payload) => {
    const response = await SBG_ById(payload);
    return response;
  }
);

export const getBuById = createAsyncThunk(
  actions.GET_BU_BYID,
  async (payload) => {
    const response = await BU_ById(payload);
    return response;
  }
);

export const getAllDesignationsList = createAsyncThunk(
  actions.GETALLDESIGNATIONSLIST,
  async (payload) => {
    const response = await allDesignations(payload);
    return response;
  }
);

export const getDesignationMappingById = createAsyncThunk(
  actions.GETDESIGNATIONMAPPINGBYID,
  async (payload) => {
    const response = await designationMappings_ById(payload);
    return response;
  }
);

export const getAllDesignationMappingsList = createAsyncThunk(
  actions.GETALLDESIGNATIONMAPPINGSLIST,
  async (payload) => {
    const response = await allDesignationsMappings(payload);
    return response;
  }
);

export const getAllSpecificityData = createAsyncThunk(
  actions.GETALLSPECIFICITYDATA,
  async (payload) => {
    const response = await search_Specifcity(payload);
    return response;
  }
);

export const getFilteredResults = createAsyncThunk(
  actions.GETFILTEREDRESULTS,
  async (payload) => {
    const response = await filter_Designations(payload);
    return response;
  }
);

export const createApprovers = createAsyncThunk(
  actions.CREATEAPPROVERS,
  async (payload) => {
    const response = await create_Approvers(payload);
    return response;
  }
);

export const createDesignations = createAsyncThunk(
  actions.CREATEDESIGNATIONS,
  async (payload) => {
    const response = await create_Designations(payload);
    return response;
  }
);

export const createDesignationMappings = createAsyncThunk(
  actions.CREATEDESIGNATIONMAPPINGS,
  async (payload) => {
    const response = await create_DesignationMappings(payload);
    return response;
  }
);

export const createProjects = createAsyncThunk(
  actions.CREATEPROJECTS,
  async (payload) => {
    const response = await create_Projects(payload);
    return response;
  }
);

export const createDoaFlow = createAsyncThunk(
  actions.CREATEDOAFLOW,
  async (payload) => {
    const response = await create_DOA_Flow(payload);
    return response;
  }
);

export const createSbu = createAsyncThunk(
  actions.CREATESBU,
  async (payload) => {
    const response = await create_SBUs(payload);
    return response;
  }
);

export const createSbg = createAsyncThunk(
  actions.CREATESBG,
  async (payload) => {
    const response = await create_SBGs(payload);
    return response;
  }
);

export const createBu = createAsyncThunk(actions.CREATEBU, async (payload) => {
  const response = await create_BUs(payload);
  return response;
});
// getAllPlants
export const getAllPlants = createAsyncThunk(
  actions.GETALLPLANTS,
  async (payload) => {
    const response = await getplantss(payload);
    return response;
  }
);
// getAllDepartments
export const getAllDepartments = createAsyncThunk(
  actions.GET_FUNCTIONS,
  async (payload) => {
    const response = await getdepartments(payload);
    return response;
  }
);
// getAllActivity
export const getAllActivity = createAsyncThunk(
  actions.GETALLACTIVITY,
  async (payload) => {
    const response = await get_activity('activities', payload);
    return response;
  }
);
// getPlantId
export const getPlantId = createAsyncThunk(
  actions.GETPLANTID,
  async (payload) => {
    // console.log('payload', payload);
    const response = await get_plant_id('plants-list', payload);
    return response;
  }
);
// EDITPLANT
export const editPlant = createAsyncThunk(
  actions.EDITPLANT,
  async (payload) => {
    const response = await edit_plant_id('plant', payload);
    return response;
  }
);
// createPlant
export const createPlant = createAsyncThunk(
  actions.CREATEPLANT,
  async (payload) => {
    // console.log('payload', payload);
    const response = await create_plant('plant', payload);
    return response;
  }
);
// createDepartment/CREATEDEPARTMENT
export const createDepartment = createAsyncThunk(
  actions.CREATEDEPARTMENT,
  async (payload) => {
    const response = await create_department('department', payload);
    return response;
  }
);
// editDepartment/EDITDEPARTMENT
export const editDepartment = createAsyncThunk(
  actions.EDITDEPARTMENT,
  async (payload) => {
    const response = await edit_department('department', payload);
    return response;
  }
);
// getDepartmentById/GETDEPARTMENTBYID
export const getDepartmentById = createAsyncThunk(
  actions.GETDEPARTMENTBYID,
  async (payload) => {
    const response = await get_department_details(
      'department-drop-down',
      payload
    );
    return response;
  }
);
// getActivityId/GETACTIVITYID
export const getActivityId = createAsyncThunk(
  actions.GETACTIVITYID,
  async (payload) => {
    const response = await get_activity_id('activities', payload);
    return response;
  }
);
// editActivity/EDITACTIVITY
export const editActivity = createAsyncThunk(
  actions.EDITACTIVITY,
  async (payload) => {
    const response = await edit_activity('activity', payload);
    return response;
  }
);
// createActivity/CREATEACTIVITY
export const createActivity = createAsyncThunk(
  actions.CREATEACTIVITY,
  async (payload) => {
    const response = await create_activity('activity', payload);
    return response;
  }
);
// getCategoryById/GETCATEGORYBYID
export const getCategoryById = createAsyncThunk(
  actions.GETCATEGORYBYID,
  async (payload) => {
    const response = await categories_ById('category-lists', payload);
    return response;
  }
);
// editCategory/EDITCATEGORY
export const editCategory = createAsyncThunk(
  actions.EDITCATEGORY,
  async (payload) => {
    const response = await edit_categories('category-lists', payload);
    return response;
  }
);
// createCategory/CREATECATEGORY
export const createCategory = createAsyncThunk(
  actions.CREATECATEGORY,
  async (payload) => {
    const response = await create_Categories('category-lists', payload);
    return response;
  }
);
// getSubCategoryById/GETSUBCATEGORYBYID
export const getSubCategoryById = createAsyncThunk(
  actions.GETSUBCATEGORYBYID,
  async (payload) => {
    const response = await subcategories_ById(
      'get-all-sub-categories',
      payload
    );
    return response;
  }
);
// editSubCategory/EDITSUBCATEGORY
export const editSubCategory = createAsyncThunk(
  actions.EDITSUBCATEGORY,
  async (payload) => {
    const response = await edit_SubCategories('sub-category', payload);
    return response;
  }
);
// createSubCategory/CREATESUBCATEGORY
export const createSubCategory = createAsyncThunk(
  actions.CREATESUBCATEGORY,
  async (payload) => {
    const response = await create_SubCategories('sub-category', payload);
    return response;
  }
);
// get_userDetails_ById/GET_USERDETAILS_BYID
export const get_userDetails_ById = createAsyncThunk(
  actions.GET_USERDETAILS_BYID,
  async (payload) => {
    const response = await userDetails_ById('get-all-users', payload);
    return response;
  }
);
// editUserDetails/EDITUSERDETAILS
export const editUserDetails = createAsyncThunk(
  actions.EDITUSERDETAILS,
  async (payload) => {
    const response = await edit_userdetails('update-user', payload);
    return response;
  }
);
// createUserInfo/CREATEUSERINFO
export const createUserInfo = createAsyncThunk(
  actions.CREATEUSERINFO,
  async (payload) => {
    const response = await create_userdetails('create-user', payload);
    return response;
  }
);

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllApproversList.pending, (state) => {
        state.tableLoading = true;
      })
      .addCase(getAllApproversList.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.all_approvers = data.data;

        state.tableLoading = false;
      })
      .addCase(getAllApproversList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.tableLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllDoaList.pending, (state) => {
        state.tableLoading = true;
      })
      .addCase(getAllDoaList.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.all_Doa_List = data;
        state.pagination = pagination || null;
        state.tableLoading = false;
      })
      .addCase(getAllDoaList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.tableLoading = false;

        message.error(msg);
      });
    // Get All Users
    builder
      .addCase(getAllUsers.pending, (state) => {
        state.tableLoading = true;
      })
      .addCase(getAllUsers.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;

        state.all_Users = data;
        state.tableLoading = false;
        state.sbu_pagination = pagination || null;
      })
      .addCase(getAllUsers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.tableLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllSbgs.pending, (state) => {
        state.tableLoading = true;
      })
      .addCase(getAllSbgs.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.tableLoading = false;
        state.all_SBGs = data;
        state.sbg_pagination = pagination;
      })
      .addCase(getAllSbgs.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.tableLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllbus.pending, (state) => {
        state.tableLoading = true;
      })
      .addCase(getAllbus.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.tableLoading = false;
        state.all_BUs = data;
        state.bu_pagination = pagination;
      })
      .addCase(getAllbus.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.tableLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllSubCategories.pending, (state) => {
        state.tableLoading = true;
      })
      .addCase(getAllSubCategories.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        console.log('Actions', data);
        state.get_subcategorylist = data;
        state.tableLoading = false;
        state.sbu_pagination = pagination || null;
      })
      .addCase(getAllSubCategories.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.tableLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getApproverById.pending, (state) => {
        state.byIdLoading = true;
      })
      .addCase(getApproverById.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.approverById = data;
        state.byIdLoading = false;
      })
      .addCase(getApproverById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.byIdLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getSbuById.pending, (state) => {
        state.byIdLoading = true;
      })
      .addCase(getSbuById.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.sbuById = data;
        state.byIdLoading = false;
      })
      .addCase(getSbuById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.byIdLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getSbgById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSbgById.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.sbgById = data;
      })
      .addCase(getSbgById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getCategoryById.pending, (state) => {
        state.byIdLoading = true;
      })
      .addCase(getCategoryById.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.category_by_id = data;
        state.byIdLoading = false;
      })
      .addCase(getCategoryById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.byIdLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getSubCategoryById.pending, (state) => {
        state.byIdLoading = true;
      })
      .addCase(getSubCategoryById.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.subcategory_by_id = data;
        state.byIdLoading = false;
      })
      .addCase(getSubCategoryById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.byIdLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getBuById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getBuById.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.buById = data;
      })
      .addCase(getBuById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getProjectById.pending, (state) => {
        state.byIdLoading = true;
      })
      .addCase(getProjectById.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.projectById = data;
        state.byIdLoading = false;
      })
      .addCase(getProjectById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.byIdLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getDesignationById.pending, (state) => {
        state.byIdLoading = true;
      })
      .addCase(getDesignationById.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.designationById = data;
        state.byIdLoading = false;
      })
      .addCase(getDesignationById.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.byIdLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllProjects.pending, (state) => {
        state.projectsLoading = true;
      })
      .addCase(getAllProjects.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;

        state.all_Projects = data;
        state.projectsLoading = false;
        state.pagination = pagination || null;
      })
      .addCase(getAllProjects.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.projectsLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getDesignationMappingById.pending, (state) => {
        state.designationMappingByIdLoading = true;
      })
      .addCase(getDesignationMappingById.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.designationMappingByIdLoading = false;
        state.designationMappingById = data;
      })
      .addCase(getDesignationMappingById.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.designationMappingByIdLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllDesignationsList.pending, (state) => {
        state.designationLoading = true;
      })
      .addCase(getAllDesignationsList.fulfilled, (state, action) => {
        const { data } = action.payload;
        console.log('AAD', data);
        state.sbu_pagination = data.pagination || null;
        state.all_designations = data.rows;

        state.designationLoading = false;
      })
      .addCase(getAllDesignationsList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.designationLoading = false;

        message.error(msg);
      });
    builder
      .addCase(getAllSpecificityData.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSpecificityData.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.specificitySearchData = data;
        state.loading = false;
      })
      .addCase(getAllSpecificityData.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });
    builder
      .addCase(getFilteredResults.pending, (state) => {
        state.filteredDataLoading = true;
      })
      .addCase(getFilteredResults.fulfilled, (state, action) => {
        const { data, pagination } = action.payload;
        state.filteredResults = data;
        state.filteredDataLoading = false;
        state.filteredPagination = pagination || null;
      })
      .addCase(getFilteredResults.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.filteredDataLoading = false;
        message.error(msg);
      });
    builder
      .addCase(getAllDesignationMappingsList.pending, (state) => {
        state.tableLoading = true;
      })
      .addCase(getAllDesignationMappingsList.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.all_designationMappings = data.data;
        state.designation_pagination = data.pagination || null;
        state.tableLoading = false;
      })
      .addCase(getAllDesignationMappingsList.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.tableLoading = false;

        message.error(msg);
      });
    builder
      .addCase(createApprovers.pending, (state) => {
        state.loading = true;
      })
      .addCase(createApprovers.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createApprovers.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(createDesignations.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDesignations.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createDesignations.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(createDesignationMappings.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDesignationMappings.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createDesignationMappings.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(createProjects.pending, (state) => {
        state.loading = true;
      })
      .addCase(createProjects.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createProjects.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createDoaFlow.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDoaFlow.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createDoaFlow.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(createSbu.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSbu.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createSbu.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    builder
      .addCase(createBu.pending, (state) => {
        state.loading = true;
      })
      .addCase(createBu.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createBu.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    // // // //
    builder
      .addCase(getAllCategories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCategories.fulfilled, (state, action) => {
        const { data } = action.payload;

        state.categories = data;
        state.loading = false;
        // state.sbu_pagination = pagination || null;
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllPlants.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllPlants.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        // console.log('action', action);
        state.loading = false;
        state.get_plants = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(getAllPlants.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllDepartments.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllDepartments.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        // console.log('action', action);
        state.loading = false;
        state.get_departments = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(getAllDepartments.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllActivity.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllActivity.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        // console.log('action', pagination);
        state.loading = false;
        state.pagination = pagination || null;
        state.get_activity = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(getAllActivity.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getPlantId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPlantId.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        // console.log('action', action);
        state.loading = false;
        state.get_plant = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(getPlantId.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    // editPlant
    builder
      .addCase(editPlant.pending, (state) => {
        state.loading = true;
      })
      .addCase(editPlant.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        // console.log('action', action);
        state.loading = false;
        state.plantDetails = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(editPlant.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    // createPlant
    builder
      .addCase(createPlant.pending, (state) => {
        state.loading = true;
      })
      .addCase(createPlant.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        // console.log('action', action);
        state.loading = false;
        state.createplantDetails = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(createPlant.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    // createDepartment
    builder
      .addCase(createDepartment.pending, (state) => {
        state.loading = true;
      })
      .addCase(createDepartment.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        // console.log('action', action);
        state.loading = false;
        state.createDepartmentDetails = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(createDepartment.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    // editDepartment
    builder
      .addCase(editDepartment.pending, (state) => {
        state.loading = true;
      })
      .addCase(editDepartment.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        // console.log('action', action);
        state.loading = false;
        state.editDepartmentDetails = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(editDepartment.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });
    // getDepartmentById
    builder
      .addCase(getDepartmentById.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDepartmentById.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        // console.log('action', action);
        state.loading = false;
        state.getDepartmentDetails = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(getDepartmentById.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });
    // getActivityId
    builder
      .addCase(getActivityId.pending, (state) => {
        state.loading = true;
      })
      .addCase(getActivityId.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        // console.log('action', action);
        state.loading = false;
        state.getActivityDetails = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(getActivityId.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });
    // editActivity
    builder
      .addCase(editActivity.pending, (state) => {
        state.loading = true;
      })
      .addCase(editActivity.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        // console.log('action', action);
        state.loading = false;
        state.editActivityDetails = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(editActivity.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });
    // createActivity
    builder
      .addCase(createActivity.pending, (state) => {
        state.loading = true;
      })
      .addCase(createActivity.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        state.loading = false;
        state.createActivityDetails = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(createActivity.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });
    // editCategory
    builder
      .addCase(editCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(editCategory.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        state.loading = false;
        state.editCategoryDetails = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(editCategory.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });
    // createCategory
    builder
      .addCase(createCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(createCategory.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createCategory.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    // editSubCategory
    builder
      .addCase(editSubCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(editSubCategory.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(editSubCategory.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
    // createSubCategory
    builder
      .addCase(createSubCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(createSubCategory.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createSubCategory.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });
    // get_userDetails_ById
    builder
      .addCase(get_userDetails_ById.pending, (state) => {
        state.loading = true;
      })
      .addCase(get_userDetails_ById.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        // console.log('action', action);
        state.loading = false;
        state.getuserDetails = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(get_userDetails_ById.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });
    // editUserDetails
    builder
      .addCase(editUserDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(editUserDetails.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(editUserDetails.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });
    // createUserInfo
    builder
      .addCase(createUserInfo.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUserInfo.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(createUserInfo.rejected, (state, action) => {
        const { message: msg } = action.error;
        state.loading = false;
        message.error(msg);
      });
  }
});

/* export const getTrackersActions = settingsSlice.actions; */
/* export const { resetTrackers } = settingsSlice.actions; */

export default settingsSlice.reducer;
