import apiClient from 'utilities/apiClient';

export const envelopes = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.create_envelop}/${url}`,
    payload,
    true
  );
};

export const create_envelop = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.create_envelop}/${url}`,
    payload,
    true,
    'file'
  );
};

export const projects = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.admin}/${url}`, payload, true);
};

export const likeenvelop = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.create_envelop}/${url}`,
    payload,
    true
  );
};

export const deleteenvelop = (url, payload) => {
  return apiClient.patch(
    `${apiClient.Urls.create_envelop}/${url}`,
    payload,
    true
  );
};
