import CustomLayout from '@/common/Layout';
import React, { useEffect, useState } from 'react';
import Dashboard from './components/dashboard';
import { useDispatch } from 'react-redux';
// useSelector
import {
  getAllEnvelopes,
  getAllProjects,
  likeEnvelope,
  deleteEnvelope
} from './redux/slice';
import IntiateEnvelop from 'features/initiate-envelope';

const Admin = () => {
  // const { getAllEnvelopLoading, allEnvelopes, projects } = useSelector(
  //   (state) => state.admin
  // );
  const dispatch = useDispatch();
  const [showAddTemplateModal, setShowAddTemplateModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [typeValue, setTypeValue] = useState(0);
  const [like, setLike] = useState(1);
  const [initiateEnv, setInitiateEnv] = useState(false);
  const [tempfileList, setTempFileList] = useState([]);
  const [searchproject, setSearchproject] = useState('');
  const [selectedProject, setSelectedProject] = useState([]);

  useEffect(() => {
    let payload = {
      type: selectedStatus.status ? selectedStatus.status : ''
    };
    handleGetAllEnvelopes(payload);
  }, [like, selectedStatus, initiateEnv]);

  useEffect(() => {
    handleGetAllProjects();
  }, [searchproject]);

  const handleGetAllEnvelopes = (payload) => {
    dispatch(getAllEnvelopes(payload));
  };

  const handleGetAllProjects = () => {
    dispatch(getAllProjects(searchproject));
  };

  const handlelike = (id) => {
    setLike(id);
    dispatch(likeEnvelope({ id: id }));
  };

  const handleDelete = (id) => {
    setLike(id);
    dispatch(deleteEnvelope({ id: id }));
  };

  return (
    <>
      <CustomLayout sider={true}>
        {initiateEnv ? (
          <IntiateEnvelop
            tempfileList={tempfileList}
            setInitiateEnv={setInitiateEnv}
            setTempFileList={setTempFileList}
          />
        ) : (
          <Dashboard
            showAddTemplateModal={showAddTemplateModal}
            setShowAddTemplateModal={setShowAddTemplateModal}
            selectedStatus={selectedStatus}
            setSelectedStatus={setSelectedStatus}
            // allEnvelopes={allEnvelopes}
            // getAllEnvelopLoading={getAllEnvelopLoading}
            // projects={projects}
            setTypeValue={setTypeValue}
            typeValue={typeValue}
            handlelike={handlelike}
            handleDelete={handleDelete}
            initiateEnv={initiateEnv}
            setInitiateEnv={setInitiateEnv}
            tempfileList={tempfileList}
            setTempFileList={setTempFileList}
            setSearchproject={setSearchproject}
            selectedProject={selectedProject}
            setSelectedProject={setSelectedProject}
          />
        )}
      </CustomLayout>
    </>
  );
};

export default Admin;
