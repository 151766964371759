import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import { Breadcrumb, Button, Col, Row, Tag, Tooltip } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import CustomLayout from '@/common/Layout/index';
import emptyState from '../../../admin-doa-envelope/images/empty.svg';
import SearchBar from '@/common/Content/searchBar';
import CustomTable from '@/common/Content/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartmentById, getAllDepartments } from '../../redux/slice';
import Pencil from '@/pages/initiate-envelope/images/Pencilsvg';
import AddModal from './AddModal';
import EditModal from './EditModal';
// import { getAllCategories } from '@/pages/dashboard/redux/slice';

const Categories = () => {
  const { get_departments, pagination, getDepartmentDetails } = useSelector(
    (state) => state.settings
  );
  // const { category_by_id, byIdLoading } = useSelector(
  //   (state) => state.settings
  // );
  // console.log('get_departments', get_departments);
  // console.log('getDepartmentDetails', getDepartmentDetails);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showEditModal = (id) => {
    let payload = {
      id: id,
      user: 'admin'
    };
    dispatch(getDepartmentById(payload));
    console.log(id);

    setIsEditModalOpen(true);
  };
  const handleEditOk = () => {
    setIsEditModalOpen(false);
  };
  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const dispatch = useDispatch();

  const handleGetDepartments = () => {
    // let payload =
    //   search !== '' ? { search: search } : { search: search, page: page };
    let payload = {
      search: search,
      page: page,
      user: 'admin' // Add the user property here
    };
    if (search === '') {
      delete payload.search; // Remove `search` if it's not needed
    }
    dispatch(getAllDepartments(payload));
  };

  useEffect(() => {
    handleGetDepartments();
  }, [search, page]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const data = get_departments;
  const columns = [
    {
      width: '60%',
      title: 'Department Name',
      dataIndex: 'function_name',
      key: 'function_name',
      render: (approvers) => <>{approvers}</>
    },

    {
      width: '20%',
      title: 'Status',
      dataIndex: 'is_active',
      key: 'is_active',
      render: (status) => (
        <Tag color={status === true ? 'green' : 'red'}>
          {status === true ? 'Active' : 'Deactivated'}
        </Tag>
      )
    },
    {
      align: 'center',
      width: '20%',
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <Tooltip title="Edit">
          <span
            className="cursor-pointer"
            onClick={() => {
              showEditModal(id);
            }}
          >
            <Pencil />
          </span>
        </Tooltip>
      )
    }
  ];

  return (
    <CustomLayout sider={false}>
      {' '}
      <div className={`${styles.breadcrub_container}`}>
        {' '}
        <Row>
          <Col span={19}>
            <Breadcrumb separator={<RightOutlined />}>
              <Breadcrumb.Item href="/settings">Settings</Breadcrumb.Item>

              <Breadcrumb.Item
                className="sfprotext-medium font-14"
                href="/settings/departments"
              >
                Configure Departments
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="text-right" span={4}>
            <Button
              onClick={showModal}
              className="font-14 sfprotext-bold "
              type="primary"
            >
              Add Department
            </Button>
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={18}>
          <div className="mt-3 font-16 sfprotext-bold">Departments</div>
        </Col>
        <Col span={6}>
          <SearchBar
            onChange={handleSearchChange}
            className={`mt-3 ${styles.createdSearch}`}
            inputProps={{
              placeholder: 'Search by department name'
            }}
          />
        </Col>
      </Row>
      {data?.length === undefined ? (
        <>
          <div className="df-jc-ac mt-5">
            <img src={emptyState} />
          </div>
          <div className="df-jc-ac mt-3">
            <div className="sfprotext-regular font-14">
              You haven’t created any categories yet.
            </div>
          </div>
          <div className="df-jc-ac mt-3">
            <Button
              onClick={showModal}
              type="primary"
              className="sfprotext-bold font-14"
            >
              Add Department
            </Button>
          </div>
        </>
      ) : (
        <CustomTable
          //loading={tableLoading}
          rowKey={(id) => id.id}
          className={`mt-4 ${styles.custom_table}`}
          /*  rowSelection={true} */
          dataSource={data}
          columns={columns}
          pagination={{
            onChange: (page) => {
              setPage(page);
            },
            pageSize: 10,
            total: pagination?.total_records,
            showSizeChanger: false
          }}
        />
      )}
      {isModalOpen ? (
        <AddModal
          // category_by_id={category_by_id?.[0]}
          department_id={getDepartmentDetails?.[0]}
          handleGetDepartments={handleGetDepartments}
          setIsModalOpen={setIsModalOpen}
          isModalVisible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
        />
      ) : null}
      {isEditModalOpen ? (
        <EditModal
          // byIdLoading={byIdLoading}
          handleGetDepartments={handleGetDepartments}
          id={id}
          setId={setId}
          // category_by_id={category_by_id?.[0]}
          department_id={getDepartmentDetails?.[0]}
          setIsEditModalOpen={setIsEditModalOpen}
          isEditModalVisible={isEditModalOpen}
          onOk={handleEditOk}
          onCancel={handleEditCancel}
        />
      ) : null}
    </CustomLayout>
  );
};

export default Categories;
