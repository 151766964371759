import React from 'react';
import CustomModal from 'common/CustomModal';
import FormItem from 'common/FormItem';
import { Space, Input, Form, Radio, Upload } from 'antd';
import CustomButton from 'common/CustomButton';
// import { useNavigate } from 'react-router-dom';

import { Select } from 'antd';
import styles from './index.module.less';
const { Option } = Select;
const { TextArea } = Input;
const AddTemplateModal = ({
  showAddTemplateModal,
  setShowAddTemplateModal,
  projects,
  setTypeValue,
  typeValue,
  setInitiateEnv,
  setTempFileList,
  tempfileList,
  setSearchproject,
  selectedProject,
  setSelectedProject
}) => {
  // const navigate = useNavigate();

  const onSearch = (value) => {
    setSearchproject(value);
  };

  const onFinish = (values) => {
    localStorage.setItem('doa_name', values.doa_envelope_name);
    localStorage.setItem('doa_description', values.doa_description);
    localStorage.setItem('project_id', values.project_id);
    typeValue === 1
      ? localStorage.setItem('envelope_type', 'standard')
      : localStorage.setItem('envelope_type', 'custom');
    // navigate('/initiate-envelope-1', { name: 'asdasd' });
    // navigate('/initiate-envelope-1', {
    //   itemId: 86,
    //   otherParam: 'anything you want here'
    // });
    setShowAddTemplateModal(false);
    setInitiateEnv(true);
  };
  const [form] = Form.useForm();
  const handleCancel = () => {
    setShowAddTemplateModal(false);
    setTempFileList([]);
  };

  const onChange = (e) => {
    setTypeValue(e.target.value);
  };

  const handleCsvUpload = async ({ fileList }) => {
    setTempFileList(fileList);
  };

  const onSelectProject = (data) => {
    setSelectedProject([...selectedProject, { project: data.project }]);
  };

  return (
    <>
      <CustomModal
        title={'Create DoA Envelopes'}
        visible={showAddTemplateModal}
        onCancel={handleCancel}
        width={'43%'}
        footer={null}
      >
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <FormItem
            label={<span>{'DoA envelope type'}</span>}
            name="type"
            // className={`${styles.astrick}`}
            rules={[
              {
                required: true,
                message: 'Please select type'
              }
            ]}
          >
            <Radio.Group onChange={onChange} value={typeValue}>
              <Radio value={1}>Standard</Radio>
              <Radio value={2}>Custom</Radio>
            </Radio.Group>
          </FormItem>
          {typeValue === 1 ? (
            <FormItem
              label={<span>{'DoA envelope template'}</span>}
              name="templates"
              rules={[
                {
                  required: true,
                  message: 'Please select template'
                }
              ]}
            >
              <Upload
                // accept={'text/csv'}
                listType="picture-card"
                className={`avatar-uploader ${styles.upload}`}
                fileList={tempfileList}
                onChange={handleCsvUpload}
                customRequest={({ onSuccess }) => {
                  setTimeout(() => {
                    onSuccess('ok');
                  }, 0);
                }}
              >
                {tempfileList.length < 5 && '+ Upload'}
              </Upload>
            </FormItem>
          ) : (
            ''
          )}
          <FormItem
            label={<span>{'DoA envelope name'}</span>}
            name="doa_envelope_name"
            // className={`${styles.astrick}`}
            rules={[
              {
                required: true,
                message: 'Please enter Doa envelope name'
              }
            ]}
          >
            <Input allowClear placeholder="Enter name" />
          </FormItem>
          <FormItem
            label={'Description'}
            name="doa_description"
            // className={`${styles.astrick}`}
          >
            <TextArea allowClear placeholder="Enter description" />
          </FormItem>
          <FormItem
            label={<span>{'Project ID'}</span>}
            name="project_id"
            // className={`${styles.astrick}`}
            rules={[
              {
                required: true,
                message: 'Please select project id'
              }
            ]}
          >
            <Select
              showSearch
              // style={{ width: 200 }}
              onSearch={onSearch}
              placeholder="Select Project"
              onSelect={onSelectProject}
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            >
              {projects.map((item, index) => {
                return (
                  <Option value={item.id} key={index}>
                    {item.project}
                  </Option>
                );
              })}
            </Select>
          </FormItem>
          <FormItem className="text-right">
            <Space>
              <CustomButton htmlType="button" onClick={handleCancel}>
                Cancel
              </CustomButton>
              <CustomButton type="primary" htmlType="submit">
                Next
              </CustomButton>
            </Space>
          </FormItem>
        </Form>
      </CustomModal>
    </>
  );
};

export default AddTemplateModal;
