import React from 'react';
import { Col, Row, Space, Typography } from 'antd';
import CustomButton from '../CustomButton';

const { Text } = Typography;

const Header = ({ title, titleSuffix, buttons, ...rest }) => {
  return (
    <Row {...rest}>
      <Col>
        <Text className="sfprotext-bold font-24">{title}</Text>
        {titleSuffix ? (
          <Text className="font-12 ml-1">{titleSuffix}</Text>
        ) : null}
      </Col>
      <Col>
        <Space>
          {buttons.map((button, index) => {
            const { type, text, onClick } = button;
            return (
              <CustomButton
                key={index}
                type={type || 'default'}
                onClick={onClick}
              >
                {text}
              </CustomButton>
            );
          })}
        </Space>
      </Col>
    </Row>
  );
};

export default Header;
