const Manage = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="20" y="20" height="100" width="100" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.18572 0C0.98074 0 0.00390625 0.976832 0.00390625 2.18182V13.819C0.00390625 15.024 0.980739 16.0008 2.18572 16.0008H13.8221C15.0271 16.0008 16.0039 15.024 16.0039 13.819V2.18182C16.0039 0.976833 15.0271 0 13.8221 0H2.18572ZM7.69463 7.99158H12.7206C12.9298 7.99158 13.0868 8.13108 13.0868 8.35778V12.7173C13.0868 12.944 12.9298 13.0835 12.7206 13.0835H7.69463C7.46795 13.0835 7.32845 12.9265 7.32845 12.7173V8.35778C7.32845 8.13108 7.48538 7.99158 7.69463 7.99158ZM3.2695 6.47849H12.7204C12.9297 6.47849 13.0866 6.33899 13.0866 6.11229V3.26584C13.0866 3.03915 12.9471 2.89964 12.7204 2.89964H3.2695C3.06025 2.89964 2.90332 3.03915 2.90332 3.26584V6.11229C2.90332 6.33899 3.06025 6.47849 3.2695 6.47849ZM5.44914 13.0835H3.2695C3.06025 13.0835 2.90332 12.9265 2.90332 12.7173V8.35778C2.90332 8.13108 3.06025 7.99158 3.2695 7.99158H5.44914C5.67582 7.99158 5.81532 8.13108 5.81532 8.35778V12.7173C5.81532 12.944 5.65838 13.0835 5.44914 13.0835Z"
        fill="rgb(255 255 255 / 85%)"
      />
    </svg>
  );
};
export default Manage;
