import React, { useEffect, useState } from 'react';
import { Col, Row, Layout, Card, Modal, Spin, Tooltip } from 'antd';

import CustomButton from '@/common/CustomButton';
import Close from '../../images/Closesvg';
// import Pencil from '../../images/Pencilsvg';
import styles from './index.module.less';
// import { useNavigate } from 'react-router-dom';

// import Draggable from 'react-draggable';
import { fileToBase64 } from 'utilities/helpers';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';

const InititateEnvelopePage2 = ({
  current,
  setCurrent,
  mergedPdfBlob,
  selectedOption,
  handleFormSubmit,
  initiate
}) => {
  // Get the value after "documents/"
  // const docUrl = 'https://tataproject.blob.core.windows.net/documents';

  // const extractedValue = valueAfterDocuments || '1.pdf';

  const [mergedPdfBlobToBase64, setMergedPdfBlobToBase64] = useState('');
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    convertMergedPdfBlobToBase64();
  }, [mergedPdfBlob]);

  const convertMergedPdfBlobToBase64 = async () => {
    let result = await fileToBase64(mergedPdfBlob);

    setMergedPdfBlobToBase64(result);
  };

  // const navigate = useNavigate();

  const handleOnclickPrev = () => {
    setCurrent(current - 1);
  };

  const Header = () => {
    return (
      <>
        <div className={`w-100 ${styles.init_env_header}`}>
          <Row>
            <div className={`df-jc-ac ${styles.x_button}`}>
              <Close />
            </div>
            <Col xs={24} xl={15} span={15}>
              <div className={`ml-3 font-18 `}>
                {`${localStorage.getItem('envelope_name')}`}
                {/* <span className={`ml-3`}>
                  <Pencil />
                </span> */}
              </div>
              <div className={`ml-3 font-14`}>
                {`${localStorage.getItem('description')}`}
                {/* <span className={`ml-3`}>
                  <Pencil />
                </span> */}
              </div>
            </Col>

            <Col className={`text-right `} xs={24} xl={8} span={8}>
              <CustomButton
                className={`mr-2`}
                onClick={() => handleOnclickPrev()}
              >
                {' '}
                Go back
              </CustomButton>
              <CustomButton
                onClick={() => handleFormSubmit()}
                type="primary"
                loading={initiate}
              >
                Initiate
              </CustomButton>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  return (
    <Layout className={`${styles.layout}`}>
      <Header />
      <div className={`${styles.container}`}>
        <Row>
          <Col className={` mr-3 text-left mt-3 `} xs={24} xl={17} span={17}>
            <Card
              className={` text-center ${styles.image_viewer_card}`}
              title={
                <div className="df-jc-ac sfprotext-bold font-18">
                  Preview your document below
                </div>
              }
              bordered={false}
            >
              <Document
                file={mergedPdfBlobToBase64}
                onLoadSuccess={onDocumentLoadSuccess}
                className={`${styles.document_viewer}`}
              >
                {Array.from(new Array(numPages), (_, index) => (
                  <Page
                    width={window.innerWidth}
                    key={`page_${index + 1}`}
                    wrap={true}
                    pageNumber={index + 1}
                    className={`${styles.document_viewer_page}`}
                    size="A4"
                  />
                ))}
              </Document>
              {/* <React.Fragment>
                <div className={`${styles.imagesBox}`}>
                  <Draggable key={version}>
                    <div>
                      <img
                        style={{
                          transform: `scale(${scale}) rotate(${rotate}deg) 
                `
                        }}
                        draggable="false"
                        src="https://placekitten.com/400/400"
                      />
                    </div>
                  </Draggable>
                </div>
              </React.Fragment> */}
            </Card>
          </Col>

          <Col className={`pl-3 text-center mr-3 mt-3`} xs={24} xl={6} span={6}>
            <Card className={` text-left ${styles.env_flow}`}>
              <p className="font-18">
                <b>Envelope Flow</b>
              </p>
              <div className={`${styles.container1}`}>
                {selectedOption?.map((item, index) => (
                  <div className={`${styles.step} `} key={index}>
                    <div className={`${styles.v_stepper}`}>
                      <div className={`${styles.circle}`}>
                        <p>{index + 1}</p>
                      </div>
                      <div className={`${styles.line}`}></div>
                    </div>

                    <div className={`${styles.content}`}>
                      <b className="font-14">
                        <Tooltip
                          placement="top"
                          title={item?.name?.slice(0, item?.name?.indexOf('-'))}
                        >
                          {item?.name?.slice(0, item?.name?.indexOf('-'))
                            .length > 30
                            ? item?.name
                                .slice(0, item?.name?.indexOf('-'))
                                .substring(0, 30) + '...'
                            : item?.name?.slice(0, item?.name?.indexOf('-'))}
                        </Tooltip>
                      </b>
                      <div className={`${styles.small_text} font-12`}>
                        Needs to sign
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </Col>
        </Row>
        <br />
      </div>
      <Modal visible={initiate} footer={false} closable={false}>
        <p style={{ textAlign: 'center' }}>Sending the document</p>
        <p style={{ textAlign: 'center' }}>
          <Spin />
        </p>
      </Modal>
    </Layout>
  );
};

export default InititateEnvelopePage2;
