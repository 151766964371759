import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import { signin } from './api';
import LocalStorage from 'utilities/localStorage';
import { history } from 'app/history';

const initialState = {
  loading: false,
  accessToken: '',
  user_details: null
};

const actions = {
  LOGIN: 'login/LOGIN'
};

export const login = createAsyncThunk(actions.LOGIN, async (payload) => {
  const response = await signin(payload);
  console.log('response', response);
  if (response?.accessToken) {
    const { accessToken, user_details } = response;
    let newUser = {
      accessToken,
      user_details
    };
    console.log('newUser', newUser.user_details.role);
    LocalStorage.setItem('gnfa_user', JSON.stringify(newUser));
    if (newUser.user_details.role === 'user') {
      history.push('/dashboard');
    } else if (newUser.user_details.role === 'admin') {
      history.push('/dashboard');
    } else if (newUser.user_details.role === 'approver') {
      history.push('/dashboard');
    }
    // else if (newUser.user.role === 'qc_initiator') {
    //   history.push('/dashboard');
    // } else if (
    //   newUser.user.role === 'prod_user' ||
    //   newUser.user.role === 'ppic_user'
    // ) {
    //   history.push('/dashboard');
    // } else if (newUser.user.role === 'qa_initiator') {
    //   history.push('/dashboard');
    // } else if (newUser.user.role === 'update_admin') {
    //   history.push('/backend_mrn');
    // } else if (newUser.user.role === 'finance_user') {
    //   history.push('/dashboard');
    // } else if (newUser.user.role === 'bd_user') {
    //   history.push('/dashboard');
    // } else if (newUser.user.role === 'qa_super_user') {
    //   history.push('/super_admin_dashboard');
    // }
  } else {
    localStorage.clear();
    logout();
    // message.success('Logged out successfully');
    history.push('/login');
  }

  return response;
});

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetLoginDetails: (state) => {
      state.token = '';
      state.user_details = null;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        // console.log('action', action);
        const { accessToken, message: msg, user_details } = action.payload;
        state.loading = false;
        state.token = accessToken;
        state.user_details = user_details;

        if (accessToken) {
          message.success(msg, [1]);
        } else {
          message.error(msg);
        }
      })
      .addCase(login.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});

export const { resetLoginDetails } = loginSlice.actions;

export const logout = () => (dispatch) => {
  localStorage.clear();
  dispatch(resetLoginDetails());
  message.success('Thanks! You have logged out successfully');
  history.push('/login');
};

export default loginSlice.reducer;
