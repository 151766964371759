import CustomLayout from '@/common/Layout';
import React from 'react';
import ActionsRequiredPage from './components/list';

function ActionsRequired() {
  return (
    <CustomLayout sider={true} tracker={true}>
      <ActionsRequiredPage />
    </CustomLayout>
  );
}

export default ActionsRequired;
