const Pencil = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        opacity="0.01"
        x="0.75"
        y="0.5"
        width="16"
        height="16"
        fill="white"
      />
      <path
        d="M9.54109 4.46094L12.4412 1.56081C12.6365 1.36555 12.9531 1.36555 13.1483 1.56081L15.4353 3.8478C15.6306 4.04306 15.6306 4.35964 15.4353 4.5549L12.5352 7.45503L9.54109 4.46094Z"
        stroke="#32324D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5223 7.36987L9.62901 4.47656L2.25107 11.7098L1.09375 15.9051L4.99972 14.8925L12.5223 7.36987Z"
        stroke="#32324D"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default Pencil;
