import React, { useState } from 'react';

import { Image, Layout, Menu /* Space */ } from 'antd';

import styles from './index.module.less';

import tataLogo from './images/granules-logo1.svg';
import Manage from './images/Managesvg.js';
import Reports from './images/Reportssvg.js';
import UserMenu from './components/UserMenu';
import { history } from 'app/history';
import { Link, NavLink } from 'react-router-dom';
// import Settings from './images/Settingssvg';
// import Templates from './images/Templatessvg';
import Home from './images/Homesvg';
import heart from './images/sideHeart.svg';
import SideDelete from './images/SideDeletesvg';
import All from './images/Allsvg';
import { getLoggedInUser } from 'utilities/helpers';
import {
  CheckCircleOutlined,
  // ClockCircleOutlined,
  CloseCircleOutlined
  // FileSyncOutlined,
  // InteractionOutlined,
  // PauseCircleOutlined,
  // StopOutlined,
  // VerticalAlignTopOutlined
  // FileDoneOutlined,
  // ArrowRightOutlined,
  // InfoCircleOutlined,
  // CloseSquareOutlined
} from '@ant-design/icons';
import VectorFileSync from './images/VectorFileSyncOutlined.svg';
import ibmPending from './images/ibmPending.svg';
import VectoriboForward from './images/VectoriboForward.svg';
import Settings from './images/Settingssvg';
// import LocalStorage from 'utilities/localStorage';
import { useSelector } from 'react-redux';
// import { getAllCounts } from '@/features/dashboard/redux/slice';

const { Header, Sider, Content } = Layout;

const CustomLayout = ({ children, sider = true, header = true, tracker }) => {
  const user = getLoggedInUser();
  // const useee = LocalStorage?.getItem('gnfa_user');
  // const shouldShowInformation = useee?.user_details?.is_information;

  const { all_trackers } = useSelector((state) => state.trackersList);
  // console.log('all_trackers', all_trackers);
  const allCounts = all_trackers;
  // const dispatch = useDispatch();
  // useEffect(() => {
  // handleGetStats();
  // }, []);

  // const handleGetStats = () => {
  //   dispatch(getAllCounts());
  // };

  const menuData = [
    {
      key: 'admin-dashboard',

      path: '/admin-dashboard',

      icon: <All />,

      label: `All DoA envelopes `
    },
    {
      key: 'favourites',

      path: '/favourites',

      icon: <img src={heart} />,

      label: 'Favourites'
    },
    {
      key: 'delete-envelop',

      path: '/delete-envelop',

      icon: <SideDelete />,

      label: 'Deleted'
    }
  ];

  // console.log('allCounts', allCounts);

  //   const InitiatedByMeCounts = allCounts.InitiatedByMe
  // console.log('InitiatedByMeCounts', InitiatedByMeCounts?.pending);

  const trackermenuData = [
    {
      key: 'pending',

      path: '/pending',

      // icon: <VectorFileSyncOutlined />,
      icon: (
        <img
          src={VectorFileSync}
          alt="pending Icon"
          style={{ width: 16, height: 16 }}
        />
      ),

      label: `Pending for action (${
        allCounts.InitiatedByOthers?.pending ?? '...'
      })`
    },
    {
      key: 'actions-required',

      path: '/actions-required',

      icon: <CheckCircleOutlined />,

      label: `Approved by me (${
        allCounts.InitiatedByOthers?.approved ?? '...'
      })`
    },

    {
      key: 'rejected',

      path: '/rejected',

      icon: <CloseCircleOutlined />,

      label: `Rejected by me  (${
        allCounts.InitiatedByOthers?.rejected ?? '...'
      })`
    },
    {
      key: 'forward',

      path: '/forward',

      // icon: <VerticalAlignTopOutlined />,
      icon: (
        <img
          src={VectoriboForward}
          alt="pending Icon"
          style={{ width: 16, height: 16 }}
        />
      ),

      label: `Forwarded by me (${
        allCounts.InitiatedByOthers?.forwarded ?? '...'
      })`
    }
  ].filter(Boolean);
  console.log('allCounts', allCounts.InitiatedByOthers);
  const InitiatedByMe = [
    {
      key: 'manage',

      path: '/manage',

      // icon: <ibmPending />,
      icon: (
        <img
          src={ibmPending}
          alt="pending Icon"
          style={{ width: 16, height: 16 }}
        />
      ),

      label: `Waiting for others (${allCounts.InitiatedByMe?.pending ?? '...'})`
    },
    {
      key: 'myapprovels',

      path: '/myapprovels',

      icon: <CheckCircleOutlined />,

      label: `My Approvals (${allCounts.InitiatedByMe?.approved ?? '...'})`
    },

    {
      key: 'myrejections',

      path: '/myrejections',

      icon: <CloseCircleOutlined />,

      label: `My Rejections  (${allCounts.InitiatedByMe?.rejected ?? '...'})`
    }
    // {
    //   key: 'forward',

    //   path: '/forward',

    //   icon: <VerticalAlignTopOutlined />,

    //   label: `Forwarded (${allCounts.InitiatedByMe?.Forwarded ?? '...'})`
    // }
  ].filter(Boolean);

  const userMenu = [
    {
      key: 'home',

      path: '/dashboard',

      icon: <Home />,

      label: 'Home'
    },

    {
      key: 'manage',

      path: '/manage',

      icon: <Manage />,

      label: 'Manage'
    },
    {
      key: 'reports',

      path: '/reports',

      icon: <Reports />,

      label: 'Reports'
    }
  ];

  const masteradmin = [
    {
      key: 'completed',

      path: '/completed-nfa',

      icon: <Reports />,

      label: `Completed NFAs`
    }
  ];

  const adminuserMenu = [
    // {
    //   key: 'home',

    //   path: '/actions-required',

    //   icon: <Home />,

    //   label: 'Home'
    // },
    // {
    //   key: 'admin-dashboard1',

    //   path: '/admin-dashboard',

    //   icon: <Templates />,

    //   label: 'Templates'
    // },
    // {
    //   key: 'reports',

    //   path: '/reports',

    //   icon: <Reports />,

    //   label: 'Reports'
    // },
    {
      key: 'home',

      path: '/dashboard',

      icon: <Home />,

      label: 'Home'
    },

    {
      key: 'manage',

      path: '/manage',

      icon: <Manage />,

      label: 'Manage'
    },
    {
      key: 'reports',

      path: '/reports',

      icon: <Reports />,

      label: 'Reports'
    },
    {
      key: 'settings',

      path: '/settings',

      icon: <Settings />,

      label: 'Settings'
    }
  ];

  let role = user?.user_details?.role;
  const admin = user?.user_details?.is_admin;
  const headerbar =
    role === 'admin'
      ? admin
        ? adminuserMenu.concat(masteradmin)
        : adminuserMenu
      : admin
      ? userMenu.concat(masteradmin)
      : userMenu;
  const [selectedMenuKeys] = useState(
    history?.location?.pathname?.replace('/', '')
  );
  let trackerData = tracker ? trackermenuData : menuData;
  // console.log('trackerData', trackerData);
  return (
    <Layout
      className={`${
        sider ? styles.custom_layout_container : styles.custom_layout_container1
      }`}
      hasSider={sider}
    >
      {sider ? (
        <Sider
          width={240}
          trigger={null}
          collapsible
          collapsed={false}
          // collapsedWidth={140}
          className={styles.sider_container}
        >
          {/* <div className={styles.sider_logo}>
            <h4 className="font-18">
              <b>DoA Envelopes</b>
            </h4>
          </div> */}

          <Menu
            // mode="inline"
            defaultSelectedKeys={['users']}
            selectedKeys={[selectedMenuKeys]}
            className={styles.custom_sidebar_container}
          >
            <div className={styles.sider_logo}>
              <h4 className="font-18">
                <b>Initiated by others</b>
              </h4>
            </div>
            {trackerData.map((item) => {
              return (
                <Menu.Item key={item?.key} label={item?.label}>
                  <NavLink to={item?.path}>
                    <span className={`mr-2  ${styles.side_icon}`}>
                      {item?.icon}
                    </span>
                    {item?.label}
                  </NavLink>
                </Menu.Item>
              );
            })}
            {role === 'admin' || role === 'user' ? (
              <>
                <div className={styles.sider_logo}>
                  <h4 className="font-18">
                    <b>Initiated by me</b>
                  </h4>
                </div>
                {InitiatedByMe.map((item) => {
                  return (
                    <Menu.Item key={item?.key} label={item?.label}>
                      <NavLink to={item?.path}>
                        <span className={`mr-2  ${styles.side_icon}`}>
                          {item?.icon}
                        </span>
                        {item?.label}
                      </NavLink>
                    </Menu.Item>
                  );
                })}
              </>
            ) : (
              ''
            )}
          </Menu>
        </Sider>
      ) : null}

      <Layout className={styles.site_layout}>
        {header ? (
          <Header className={`df-jb-ac ${styles.site_layout_header}`}>
            <div className="sfprotext-medium font-16">
              <Link to="/dashboard">
                <Image
                  className={`${styles.logo_header}`}
                  alt="TATA projects logo"
                  src={tataLogo}
                  preview={false}
                />
              </Link>
            </div>

            <Menu className={` df-jc-ac ${styles.menu}`} mode="horizontal">
              {headerbar.map((item) => {
                return (
                  <Menu.Item key={item.key}>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? `${styles.active}` : `${styles.inactive}`
                      }
                      to={item.path}
                    >
                      <span className={` ${styles.icon}`}>
                        {item.icon} {item.label}
                      </span>
                    </NavLink>
                  </Menu.Item>
                );
              })}
            </Menu>
            <UserMenu />
          </Header>
        ) : null}

        <Content className={styles.site_layout_content}>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default CustomLayout;
