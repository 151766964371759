import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  get_AllStandardEnvelopes,
  // stats,
  get_AllCustomEnvelopes,
  // price,
  // categories,
  // subcategories,
  standardDoa,
  // projects,
  // businessunits,
  getlocationss,
  getplantss,
  dashboard_count,
  get_functions,
  get_category,
  get_subcategory,
  get_activity
} from './api';
// import LocalStorage from 'utilities/localStorage';

const initialState = {
  loading: false,
  // allStats: [],
  // allCounts: [],
  // getAllApproverLoading: false,
  // allStandardEnvelopes: [],
  // allCustomEnvelopes: [],
  // subcategories: [],
  // pricebrackets: [],
  // categories: [],
  // doa: {},
  // projects: [],
  // bu: [],
  get_locations: [],
  dashboard_count: [],
  get_functions: [],
  get_category: [],
  get_subcategorylist: [],
  get_plants: [],
  get_activity: []
};

const actions = {
  // GET_ALL_COUNTS: 'getAllSCounts/GET_ALL_COUNTS',
  // GET_ALL_STATS: 'getAllStats/GET_ALL_STATS',
  GET_ALL_STANDARD_ENVELOPES:
    'getAllStandardEnvelopes/GET_ALL_STANDARD_ENVELOPES',
  GET_ALL_CUSTOM_ENVELOPES: 'getAllCustomEnvelopes/GET_ALL_CUSTOM_ENVELOPES',
  // GET_ALL_SUB_CATEGORIES: 'getAllSubCategories/GET_ALL_SUB_CATEGORIES',
  // GET_ALL_PRICE_BRACKETS: 'getAllPricebrackets/GET_ALL_PRICE_BRACKETS',
  // GET_ALL_CATEGORIES: 'getAllCategories/GET_ALL_CATEGORIES',
  GET_DOA: 'getdoa/GET_DOA',
  // GET_ALL_PROJECTS: 'getAllProjects/GET_ALL_PROJECTS',
  // GET_ALL_BUS: 'getAllBUs/GET_ALL_BUS',

  GET_LOCATIONS: 'getLocations/GET_LOCATIONS',
  GET_DASHBOARD_COUNT: 'getdashboardCount/GET_DASHBOARD_COUNT',
  GET_FUNCTIONS: 'getFunctions/GET_FUNCTIONS',
  GET_CATEGORY: 'getcategory/GET_CATEGORY',
  GET_SUBCATEGORY_LIST: 'getsubCategory/GET_SUBCATEGORY_LIST',
  GET_PLANTS: 'getPlants/GET_PLANTS',
  GET_ACTIVITY: 'getactivity/GET_ACTIVITY'
};

// NFA Granules Locations data
export const getLocations = createAsyncThunk(
  actions.GET_LOCATIONS,
  async (payload) => {
    const response = await getlocationss('locations', payload);
    return response;
  }
);

// NFA Granules Plants data
export const getPlants = createAsyncThunk(
  actions.GET_PLANTS,
  async (payload) => {
    const response = await getplantss('plants', payload);
    return response;
  }
);

export const getdashboardCount = createAsyncThunk(
  actions.GET_DASHBOARD_COUNT,
  async (payload) => {
    const response = await dashboard_count('dashboardcount', payload);
    return response;
  }
);

export const getfunctions = createAsyncThunk(
  actions.GET_FUNCTIONS,
  async (payload) => {
    const response = await get_functions('functions', payload);
    return response;
  }
);
// getcategory
export const getcategory = createAsyncThunk(
  actions.GET_CATEGORY,
  async (payload) => {
    const response = await get_category('category', payload);
    return response;
  }
);
// getsubCategory
export const getsubCategory = createAsyncThunk(
  actions.GET_SUBCATEGORY_LIST,
  async (payload) => {
    // const response = await get_subcategory('subcategorylist/${payload}', payload);
    const response = await get_subcategory(
      `sub-category-lists/${payload}`,
      // `sub-category-lists`,
      payload
    );
    return response;
  }
);

export const getdoa = createAsyncThunk(actions.GET_DOA, async (payload) => {
  const response = await standardDoa('get-templates', payload);
  return response;
});

export const getAllStandardEnvelopes = createAsyncThunk(
  actions.GET_ALL_STANDARD_ENVELOPES,
  async (payload) => {
    const response = await get_AllStandardEnvelopes('all-standard', payload);
    return response;
  }
);

export const getAllCustomEnvelopes = createAsyncThunk(
  actions.GET_ALL_CUSTOM_ENVELOPES,
  async (payload) => {
    const response = await get_AllCustomEnvelopes('all-custom', payload);
    return response;
  }
);

// getActivity
export const getactivity = createAsyncThunk(
  actions.GET_ACTIVITY,
  async (payload) => {
    const response = await get_activity('activities', payload);
    return response;
  }
);

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLocations.pending, (state) => {
        state.loading = true;
      })
      .addCase(getLocations.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        // console.log('action', action);
        state.loading = false;
        state.get_locations = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(getLocations.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getPlants.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPlants.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        // console.log('action', action);
        state.loading = false;
        state.get_plants = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(getPlants.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getdashboardCount.pending, (state) => {
        state.loading = true;
      })
      .addCase(getdashboardCount.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        // console.log('action', action);
        state.loading = false;
        state.dashboard_count = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(getdashboardCount.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getfunctions.pending, (state) => {
        state.loading = true;
      })
      .addCase(getfunctions.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        // console.log('action', action);
        state.loading = false;
        state.get_functions = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(getfunctions.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getcategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getcategory.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        // console.log('action', action);
        state.loading = false;
        state.get_category = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(getcategory.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getsubCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(getsubCategory.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        console.log('action', data);
        state.loading = false;
        state.get_subcategorylist = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(getsubCategory.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getdoa.pending, (state) => {
        state.getAllEnvelopLoading = true;
      })
      .addCase(getdoa.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.getAllEnvelopLoading = false;
        state.doa = data ? data : '';
      })
      .addCase(getdoa.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvelopLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllStandardEnvelopes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllStandardEnvelopes.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        state.loading = false;
        state.allStandardEnvelopes = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(getAllStandardEnvelopes.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllCustomEnvelopes.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllCustomEnvelopes.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        state.loading = false;
        state.allCustomEnvelopes = data;
        if (success) {
          /* message.success(msg); */
        } else {
          /* message.error(msg); */
        }
      })
      .addCase(getAllCustomEnvelopes.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });

    builder
      .addCase(getactivity.pending, (state) => {
        state.loading = true;
      })
      .addCase(getactivity.fulfilled, (state, action) => {
        const { success, data } = action.payload;
        // console.log('action', action);
        state.loading = false;
        state.get_activity = data;
        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(getactivity.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.loading = false;

        message.error(msg);
      });
  }
});
export default dashboardSlice.reducer;
