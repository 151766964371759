import React, { useEffect, useState } from 'react';
import ReviewActionsPage from './components/viewFile';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getfilebyid,
  // getOtp,
  reject_envelope,
  getRejectedApprovers,
  cancel_envelope,
  approve_envelope,
  forward_envelope,
  return_envelope
} from './redux/slice';
import { getLoggedInUser } from 'utilities/helpers';
import { history } from 'app/history';
import { getAllApprovers } from '../initiate-envelope/redux/slice';

function ReviewActions() {
  // , otp, rejected_approvers, allFileLoading
  const { all_file_data } = useSelector((state) => state.reviewActions);
  // console.log('all_file_data', all_file_data);
  const { allApprovers } = useSelector((state) => state.approvers);
  const [fileList, setFileList] = useState([]);
  const [modalVisible, setModalVisible] = useState('');
  const [editMode, setEditMode] = useState(false);
  const dispatch = useDispatch();
  const params = useParams();

  const user = getLoggedInUser();

  useEffect(() => {
    handleGetfileById();
    handleGetAllApprovers();
  }, []);

  const handleGetAllApprovers = () => {
    dispatch(getAllApprovers());
  };

  const handleGetfileById = () => {
    dispatch(getfilebyid(params.id));
  };

  const handleSetReject = (type) => {
    let payload = {
      envelope_id: params.id
    };
    dispatch(getRejectedApprovers(payload));
    setModalVisible(type);
  };

  const handleSetApprove = (type) => {
    // let payload = {
    //   envelope_id: params.id
    // };
    // dispatch(getRejectedApprovers(payload));
    setModalVisible(type);
  };

  // const sentotp = (type) => {
  //   const paylaod = { email: user.user_details.email, envelope_id: params.id };
  //   dispatch(getOtp(paylaod));
  //   setModalVisible(type);
  // };

  console.log('setModalVisible', modalVisible);
  console.log('userEmail', user.user_details.email);
  console.log('envelope_id', params.id);

  const onFinish = (values) => {
    console.log('values', values);

    let payload = {
      email: user.user_details.email,
      reviewer: values.reviewer || '',
      forwarded_to_id: values.reviewerId || '',
      comments: values.comments || '',
      envelope_id: params.id
    };

    // const actiontaken =
    //   modalVisible === 'approved' ? approve_envelope : reject_envelope;

    let actionTaken;

    if (modalVisible === 'approved') {
      actionTaken = approve_envelope;
    } else if (modalVisible === 'reject') {
      actionTaken = reject_envelope;
    } else if (modalVisible === 'forward') {
      actionTaken = forward_envelope;
    } else if (modalVisible === 'returned') {
      actionTaken = return_envelope;
    } else {
      // Default or error handling if needed
      console.error('Unknown modal status');
    }

    dispatch(actionTaken(payload)).then((response) => {
      if (response.payload.success) {
        history.push('/actions-required');
      }
    });

    // {
    //   modalVisible !== 'approved'
    //     ? dispatch(reject_envelope(formData)).then((response) => {
    //         if (response.payload.success) {
    //           history.push('/actions-required');
    //         }
    //       })
    //     : dispatch(approve_envelope(formData)).then((response) => {
    //         if (response.payload.success) {
    //           history.push('/actions-required');
    //         }
    //       });
    // }
    setModalVisible('');
    // setFileList([]);
  };

  const onFinishCancel = (values) => {
    values['envelope_id'] = params.id;
    values['status'] = 'Cancelled';
    dispatch(cancel_envelope(values)).then((response) => {
      if (response.payload.success) {
        history.push('/cancelled');
      }
    });
  };

  return (
    <>
      <ReviewActionsPage
        // allFileLoading={allFileLoading}
        fileList={fileList}
        setFileList={setFileList}
        allApprovers={allApprovers}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        all_file_data={all_file_data}
        // sentotp={sentotp}
        // otp={otp}
        onFinish={onFinish}
        handleSetReject={handleSetReject}
        handleSetApprove={handleSetApprove}
        // rejected_approvers={rejected_approvers}
        setEditMode={setEditMode}
        editMode={editMode}
        onFinishCancel={onFinishCancel}
      />
    </>
  );
}

export default ReviewActions;
