import CustomLayout from '@/common/Layout';
import React from 'react';
import SettingsPage from './components/Settings';

const Settings = () => {
  return (
    <CustomLayout tracker={true} sider={false}>
      <SettingsPage />
    </CustomLayout>
  );
};

export default Settings;
