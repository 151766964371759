import React from 'react';
import ResetPassswordPage from './components';

const index = () => {
  return (
    <>
      <ResetPassswordPage />
    </>
  );
};

export default index;
