import React, { useEffect, useState } from 'react';
import styles from './index.module.less';
import { Breadcrumb, Button, Col, Row, Tooltip } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import CustomLayout from '@/common/Layout/index';
import emptyState from '../../../admin-doa-envelope/images/empty.svg';
import SearchBar from '@/common/Content/searchBar';
import CustomTable from '@/common/Content/CustomTable';
import { useDispatch, useSelector } from 'react-redux';
import { getAllUsers, get_userDetails_ById } from '../../redux/slice';
// ,getAllSbus
import { getfunctions, getLocations } from '../../../dashboard/redux/slice';
import Pencil from '@/pages/initiate-envelope/images/Pencilsvg';
import AddModal from './AddModal';
import EditModal from './EditModal';

const Usermanagement = () => {
  const { all_Users, getuserDetails } = useSelector((state) => state.settings);
  const { get_functions, get_locations } = useSelector(
    (state) => state.dashboard
  );
  // all_SBUs, sbuById, sbu_pagination, all_SBGs
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [id, setId] = useState('');
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [sbgSearch, setSbgSearch] = useState('');
  const [departmentSearch, setDepartmentSearch] = useState('');
  const [locationSearch, setLocationSearch] = useState('');
  const [page, setPage] = useState(1);

  console.log('get_locations', get_locations);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    handlegetfunctions();
    handlegetLocations();
  }, []);

  useEffect(() => {
    let payload =
      search !== ''
        ? { search: search, page: page }
        : { search: search, page: page };

    handlegetfunctions(payload);
  }, [departmentSearch]);

  useEffect(() => {
    let payload =
      search !== ''
        ? { search: search, page: page }
        : { search: search, page: page };

    handlegetLocations(payload);
  }, [locationSearch]);

  const showEditModal = (id) => {
    // const payload = { id: id };
    // dispatch(getSbuById(payload));
    let payload = {
      id: id,
      user: 'admin'
    };
    dispatch(get_userDetails_ById(payload));
    setIsEditModalOpen(true);
  };
  const handlegetfunctions = () => {
    dispatch(getfunctions());
  };
  const handlegetLocations = () => {
    dispatch(getLocations());
  };
  const handleEditOk = () => {
    setIsEditModalOpen(false);
  };
  const handleEditCancel = () => {
    setIsEditModalOpen(false);
  };

  const dispatch = useDispatch();

  const handleGetAllApprovers = () => {
    let payload =
      search !== ''
        ? { search: search, page: page }
        : { search: search, page: page };
    //   , page: page
    dispatch(getAllUsers(payload));
  };

  const handleGetAllSBGs = () => {
    let payload = sbgSearch !== '' ? { search: sbgSearch } : {};
    dispatch(getAllUsers(payload));
  };

  useEffect(() => {
    handleGetAllSBGs();
  }, [sbgSearch]);

  useEffect(() => {
    handleGetAllApprovers();
  }, [search]);
  //   , page

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handleSbgSearchChange = (e) => {
    setSbgSearch(e);
  };
  const handleDepartmentSearchChange = (e) => {
    setDepartmentSearch(e);
  };
  const handleLocationSearchChange = (e) => {
    setLocationSearch(e);
  };
  // const data = all_Users?.map((item) => {
  //   const { id, sbu_name, is_active } = item;
  //   return {
  //     ...item,
  //     status: {
  //       is_active
  //     },
  //     approvers: {
  //       sbu_name
  //     },

  //     id: { id }
  //   };
  // });

  const data = all_Users;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'display_name',
      key: 'display_name',
      render: (display_name) => <>{display_name}</>
    },
    {
      title: 'E-Mail',
      dataIndex: 'email',
      key: 'email',
      render: (email) => <>{email}</>
    },
    {
      title: 'Designation',
      dataIndex: 'designations',
      key: 'designation',
      render: (designations) => <>{designations?.[0]?.designation || 'N/A'}</>
    },
    {
      title: 'Function',
      dataIndex: 'departments',
      key: 'function_name',
      render: (departments) => <>{departments?.[0]?.function_name || 'N/A'}</>
    },
    {
      title: 'Location',
      dataIndex: 'locations',
      key: 'location_name',
      render: (locations) => <>{locations?.[0]?.location_name || 'N/A'}</>
    },
    {
      title: 'Contact',
      dataIndex: 'contact',
      key: 'contact',
      render: (contact) => <>{contact}</>
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'is_active',
    //   key: 'is_active',
    //   render: (is_active) => (
    //     <Tag color={is_active === true ? 'green' : 'grey'}>
    //       {is_active === true ? 'Active' : 'Inactive'}
    //     </Tag>
    //   )
    // },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <Tooltip title="Edit">
          <span
            className="cursor-pointer"
            onClick={() => {
              showEditModal(id);
            }}
          >
            <Pencil />
          </span>
        </Tooltip>
      )
    }
  ];

  return (
    <CustomLayout sider={false}>
      {' '}
      <div className={`${styles.breadcrub_container}`}>
        {' '}
        <Row>
          <Col span={19}>
            <Breadcrumb separator={<RightOutlined />}>
              <Breadcrumb.Item href="/settings">Settings</Breadcrumb.Item>

              <Breadcrumb.Item
                className="sfprotext-medium font-14"
                href="/settings/usermanagement"
              >
                {/* Configure SBUs */}
                User Management
              </Breadcrumb.Item>
            </Breadcrumb>
          </Col>
          <Col className="text-right" span={4}>
            <Button
              onClick={showModal}
              className="font-14 sfprotext-bold "
              type="primary"
            >
              Add User
            </Button>
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={18}>
          <div className="mt-3 font-16 sfprotext-bold">User</div>
        </Col>
        <Col span={6}>
          <SearchBar
            onChange={handleSearchChange}
            className={`mt-3 ${styles.createdSearch}`}
            inputProps={{
              placeholder: 'Search by User Name'
            }}
          />
        </Col>
      </Row>
      {data?.length === undefined ? (
        <>
          <div className="df-jc-ac mt-5">
            <img src={emptyState} />
          </div>
          <div className="df-jc-ac mt-3">
            <div className="sfprotext-regular font-14">
              You haven’t created any user yet.
            </div>
          </div>
          <div className="df-jc-ac mt-3">
            <Button
              onClick={showModal}
              type="primary"
              className="sfprotext-bold font-14"
            >
              Add User
            </Button>
          </div>
        </>
      ) : (
        <CustomTable
          rowKey={(id) => id.id.id}
          className={`mt-4 ${styles.custom_table}`}
          pagination={{
            onChange: (page) => {
              setPage(page);
            },
            pageSize: 20,
            // total: sbu_pagination?.total_records,
            showSizeChanger: false
          }}
          dataSource={data}
          columns={columns}
        />
      )}
      {isModalOpen ? (
        <AddModal
          handleSbgSearchChange={handleSbgSearchChange}
          //   all_SBGs={all_SBGs}
          getuserDetails={getuserDetails?.[0]}
          handleGetAllApprovers={handleGetAllApprovers}
          setIsModalOpen={setIsModalOpen}
          isModalVisible={isModalOpen}
          onOk={handleOk}
          onCancel={handleCancel}
          departments={get_functions}
          locations={get_locations}
          handleDepartmentSearchChange={handleDepartmentSearchChange}
          handleLocationSearchChange={handleLocationSearchChange}
        />
      ) : null}
      {isEditModalOpen ? (
        <EditModal
          handleSbgSearchChange={handleSbgSearchChange}
          getuserDetails={getuserDetails?.[0]}
          //   all_SBGs={all_SBGs}
          handleGetAllApprovers={handleGetAllApprovers}
          id={id}
          setId={setId}
          //   sbuById={sbuById?.[0]}
          setIsEditModalOpen={setIsEditModalOpen}
          isEditModalVisible={isEditModalOpen}
          onOk={handleEditOk}
          onCancel={handleEditCancel}
          departments={get_functions}
          locations={get_locations}
          handleDepartmentSearchChange={handleDepartmentSearchChange}
          handleLocationSearchChange={handleLocationSearchChange}
        />
      ) : null}
    </CustomLayout>
  );
};

export default Usermanagement;
