// LoginPage.js
import CustomButton from '@/common/CustomButton';
import React, { useState, useEffect } from 'react';
import { Form, Input } from 'antd';
import styles from './index.module.less'; // Assuming you have a Less module for this component
// import { history } from 'app/history';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../login/images/granules_logo.png';
import FormItem from 'common/FormItem';
import { SetPassword } from '../redux/slice';
import { Message, Icon } from 'semantic-ui-react';

// const { Link } = Typography;
function index({ loading }) {
  const [form] = Form.useForm();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [password, setPassword] = useState('');
  const [cpassword, setCpassword] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const searchParam = useLocation().search;
  const searchParams = new URLSearchParams(searchParam);
  const token = searchParams.get('token');

  useEffect(() => {
    // Check if passwords match and update the button's disabled state
    setIsButtonDisabled(password !== cpassword || !password || !cpassword);
  }, [password, cpassword]);

  const onFinish = (values) => {
    const { password, cpassword } = values;

    if (password !== cpassword) {
      setErrorMsg('Passwords do not match');
      return;
    } else {
      setIsButtonDisabled(false);
    }

    let payload = {
      password,
      token
    };
    // console.log('payload', payload);
    onSubmit(payload);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // const onSubmit = (payload) => {
  //   dispatch(SetPassword(payload));
  // };

  const onSubmit = async (payload) => {
    const response = await dispatch(SetPassword(payload));
    console.log('payload1', response);
    if (response.payload.success) {
      navigate('/login');
    }
  };

  // const backToLogin = () => {
  //   history.push('login');
  // };

  return (
    <div className={`${styles.fullContainer}`}>
      <div className={styles.leftSide}>
        <div className={styles.loginleft}>
          <h1>Manage your document workflows</h1>
          <p>
            Make your job easy by creating envelopes and sharing it across for
            approvals. Also it`s very easy to track the shared documents and
            create templates of your own.
          </p>
        </div>
      </div>

      {/* <div className={styles.rightSide}> */}

      <div className={styles.rightSide}>
        <div className={styles.container}>
          <div className={styles.logoContainer}>
            <img src={logo} alt="logo" />
          </div>
          <div className={styles.formheader}>
            <h1
              className={styles.formtitle}
              style={{
                color: '#0098DA'
              }}
            >
              Reset password
            </h1>
          </div>

          <div className={` ${styles.right_outer_container}`}>
            <Form
              form={form}
              layout="vertical"
              className={`ant-form ant-form-vertical mt-4 ${styles.form_width}`}
              onFinish={onFinish}
            >
              <FormItem
                label="New Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
              >
                <Input.Password
                  placeholder="Enter your new password"
                  className={styles.customLable}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormItem>
              <FormItem
                label="Confirm Password"
                name="cpassword"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error('The passwords do not match for the above!')
                      );
                    }
                  })
                ]}
              >
                <Input.Password
                  placeholder="Re-enter new password"
                  className={styles.customLable}
                  onChange={(e) => setCpassword(e.target.value)}
                />
              </FormItem>
              <FormItem>
                <CustomButton
                  // block
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  disabled={isButtonDisabled}
                  className={styles.login_button}
                >
                  Submit
                  {/* <ArrowRightOutlined /> */}
                </CustomButton>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
      <div>
        {errorMsg && (
          <Message negative>
            <Icon name="warning" />
            {errorMsg}
          </Message>
        )}
      </div>
    </div>

    // </div>
  );
}

export default index;
