import React from 'react';
import Layout from '@/common/Layout';
import ReportPage from './components/ReportPage';

const Reports = () => {
  return (
    <>
      <Layout sider={false}>
        <ReportPage />
      </Layout>
    </>
  );
};

export default Reports;
