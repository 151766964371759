import React from 'react';
import { Col, Divider, Dropdown, Input, Row, Space, Typography } from 'antd';
import { SearchOutlined, CaretDownOutlined } from '@ant-design/icons';
import CustomButton from '@/common/CustomButton';
const { Text } = Typography;

const SearchBar = ({
  inputProps,
  filters,
  menu,
  rolesMenu,
  statusMenu,
  dateMenu,
  selectedRole,
  selectedStatus,
  periodMenu,
  selectedPeriod,
  MRMenu,
  selectedUserType,
  button,
  ...rest
}) => {
  return (
    <Row {...rest}>
      {inputProps ? (
        <Col xl={7} xs={24} span={7}>
          <Input prefix={<SearchOutlined />} {...inputProps} />
        </Col>
      ) : null}
      {filters ? (
        <Col span={12}>
          <Space>
            <Text className="spfrotext-medium">Filters:</Text>
            {window.location.pathname === '/doctors' ||
            window.location.pathname === '/pharmacy' ? (
              <>
                <Dropdown overlay={menu} trigger={['click']}>
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    {selectedRole ? (
                      selectedRole.label
                    ) : (
                      <span>
                        Internal Priority <CaretDownOutlined />
                      </span>
                    )}
                  </a>
                </Dropdown>
                <Divider type="vertical" />
                <Dropdown overlay={statusMenu} trigger={['click']}>
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    {selectedStatus ? (
                      selectedStatus.label
                    ) : (
                      <span>
                        All statuses <CaretDownOutlined />
                      </span>
                    )}
                  </a>
                </Dropdown>{' '}
                <span className="divider">|</span>
                <Dropdown overlay={dateMenu} trigger={['click']}>
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    {selectedStatus ? (
                      selectedStatus.label
                    ) : (
                      <span>
                        All statuses <CaretDownOutlined />
                      </span>
                    )}
                  </a>
                </Dropdown>{' '}
              </>
            ) : window.location.pathname === '/visits-list' ||
              window.location.pathname === '/visits-history' ? (
              <>
                <Dropdown overlay={MRMenu} trigger={['click']}>
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    {selectedUserType ? (
                      selectedUserType.status
                    ) : (
                      <span>
                        User Type <CaretDownOutlined />
                      </span>
                    )}
                  </a>
                </Dropdown>
                {window.location.pathname != '/visits-list' ? (
                  <>
                    <Divider type="vertical" />
                    <Dropdown overlay={statusMenu} trigger={['click']}>
                      <a
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                      >
                        {selectedStatus ? (
                          selectedStatus.label
                        ) : (
                          <span>
                            All statuses <CaretDownOutlined />
                          </span>
                        )}
                      </a>
                    </Dropdown>
                  </>
                ) : (
                  ''
                )}
                <Divider type="vertical" />
                <Dropdown overlay={periodMenu} trigger={['click']}>
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    {selectedPeriod ? (
                      selectedPeriod.title
                    ) : (
                      <span>
                        Period <CaretDownOutlined />
                      </span>
                    )}
                  </a>
                </Dropdown>
              </>
            ) : window.location.pathname === '/MRS' ? (
              <>
                <Dropdown overlay={menu} trigger={['click']}>
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    {selectedRole ? (
                      selectedRole.label
                    ) : (
                      <span>
                        Therapy Associate-1 Type <CaretDownOutlined />
                      </span>
                    )}
                  </a>
                </Dropdown>
                <Divider type="vertical" />
                <Dropdown overlay={statusMenu} trigger={['click']}>
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    {selectedStatus ? (
                      selectedStatus.label
                    ) : (
                      <span>
                        All statuses <CaretDownOutlined />
                      </span>
                    )}
                  </a>
                </Dropdown>
              </>
            ) : (
              <>
                {window.location.pathname === '/users' ? (
                  <>
                    <Dropdown overlay={rolesMenu} trigger={['click']}>
                      <a
                        className="ant-dropdown-link"
                        onClick={(e) => e.preventDefault()}
                      >
                        {selectedRole ? (
                          selectedRole.title
                        ) : (
                          <span>
                            All user roles <CaretDownOutlined />
                          </span>
                        )}
                      </a>
                    </Dropdown>
                    <Divider type="vertical" />
                  </>
                ) : (
                  ''
                )}
                Statuses
                <Dropdown overlay={statusMenu} trigger={['click']}>
                  <a
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                  >
                    {selectedStatus ? (
                      <>
                        <span>{selectedStatus.label}</span>
                        <span>
                          {' '}
                          <CaretDownOutlined />
                        </span>
                      </>
                    ) : (
                      <span>
                        All <CaretDownOutlined />
                      </span>
                    )}
                  </a>
                </Dropdown>
              </>
            )}
          </Space>
        </Col>
      ) : null}
      {button ? (
        <Col span={6}>
          <span>
            <CustomButton
              style={{
                float: 'right'
                // marginRight: '-92px'
              }}
            >
              Create template
            </CustomButton>
          </span>
        </Col>
      ) : (
        ''
      )}
    </Row>
  );
};

export default SearchBar;
