import apiClient from '../../../utilities/apiClient';

export const allApprovers = (payload) => {
  return apiClient.get(`${apiClient.Urls.all_approvers}/all`, payload, true);
};

export const allProjects = (payload) => {
  return apiClient.get(`${apiClient.Urls.all_projects}`, payload, true);
};

export const allDoaFlows = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.all_standard_envelopes}/doa_list`,
    payload,
    true
  );
};

export const allSubCategories = (payload) => {
  return apiClient.get(`${apiClient.Urls.subcategories}/all`, payload, true);
};

export const categories_ById = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.categories}/all?id=${payload}`,
    payload,
    true
  );
};
export const subcategories_ById = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.subcategories}/all?id=${payload}`,
    payload,
    true
  );
};

export const allDesignations = (payload) => {
  return apiClient.get(`${apiClient.Urls.all_designations}/all`, payload, true);
};

export const allSBUs = (payload) => {
  return apiClient.get(`${apiClient.Urls.all_sbu}/all`, payload, true);
};

export const allSBGs = (payload) => {
  return apiClient.get(`${apiClient.Urls.all_sbg}/all`, payload, true);
};

export const allBUs = (payload) => {
  return apiClient.get(`${apiClient.Urls.unit}/all`, payload, true);
};

export const allDesignationsMappings = (payload) => {
  return apiClient.get(`${apiClient.Urls.all_mappings}/all`, payload, true);
};

export const approver_ById = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.all_approvers}/approval_role/${payload}`,
    payload,
    true
  );
};

export const designation_ById = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.all_designations}/designation/${payload}`,
    payload,
    true
  );
};

export const project_ById = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.admin}/projectby/${payload}`,
    payload,
    true
  );
};

export const designationMappings_ById = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.all_mappings}/mapping/${payload}`,
    payload,
    true
  );
};

export const SBU_ById = (payload) => {
  return apiClient.get(`${apiClient.Urls.all_sbu}/all`, payload, true);
};

export const BU_ById = (payload) => {
  return apiClient.get(`${apiClient.Urls.unit}/all`, payload, true);
};

export const SBG_ById = (payload) => {
  return apiClient.get(`${apiClient.Urls.all_sbg}/all`, payload, true);
};

export const create_Designations = (payload) => {
  return apiClient.post(
    `${apiClient.Urls.all_designations}/add`,
    payload,
    true
  );
};

export const create_DesignationMappings = (payload) => {
  return apiClient.post(`${apiClient.Urls.all_mappings}/add`, payload, true);
};

export const create_Approvers = (payload) => {
  return apiClient.post(`${apiClient.Urls.all_approvers}/add`, payload, true);
};

export const create_Projects = (payload) => {
  return apiClient.post(`${apiClient.Urls.admin}/add-project`, payload, true);
};

export const create_SBUs = (payload) => {
  return apiClient.post(`${apiClient.Urls.all_sbu}/add`, payload, true);
};

export const create_BUs = (payload) => {
  return apiClient.post(`${apiClient.Urls.unit}/add`, payload, true);
};

export const create_SBGs = (payload) => {
  return apiClient.post(`${apiClient.Urls.all_sbg}/add`, payload, true);
};

export const create_Categories = (payload) => {
  return apiClient.post(`${apiClient.Urls.categories}/add`, payload, true);
};

export const create_DOA_Flow = (payload) => {
  return apiClient.post(
    `${apiClient.Urls.all_standard_envelopes}/create-doa-flow`,
    payload,
    true
  );
};
export const create_SubCategories = (payload) => {
  return apiClient.post(`${apiClient.Urls.subcategories}/add`, payload, true);
};

export const search_Specifcity = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.create_envelop}/specificity_search`,
    payload,
    true
  );
};

export const filter_Designations = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.create_envelop}/dsg_filter`,
    payload,
    true
  );
};
