import React, { useEffect } from 'react';
import { Form, Col, Row, Input } from 'antd';
import Close from '../../images/Closesvg';
import Pencil from '../../images/Pencilsvg';
import FormItem from 'common/FormItem';
import CustomButton from '@/common/CustomButton';
import styles from './index.module.less';
import { Link } from 'react-router-dom';
// import { Select } from 'antd';

// const { Option } = Select;
const AdminHeader = ({
  handleSave,
  // project,
  edit_env,
  handleEdit,
  envtype,
  type
  // projects,
  // projectId
}) => {
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({
      doa_name: localStorage.getItem('doa_name'),
      doa_description: localStorage.getItem('doa_description'),
      project_id: parseInt(localStorage.getItem('project_id'))
    });
  });
  return (
    <div className={`w-100 ${styles.init_env_header}`}>
      <Row>
        <Link to="/admin-dashboard">
          <div className={`df-jc-ac ${styles.x_button}`}>
            <Close />
          </div>
        </Link>
        <Col span={15}>
          <div className={`ml-3 font-18 `}>
            {envtype ? (
              <Row gutter={8}>
                <Col span={12}>
                  <Form form={form} layout="vertical">
                    <FormItem name="doa_name">
                      <Input
                        placeholder="Enter envelope name"
                        onBlur={(e) => handleEdit(e.target.value, 'doa_name')}
                        autoFocus
                      />
                    </FormItem>
                  </Form>
                </Col>
              </Row>
            ) : (
              `${localStorage.getItem('doa_name')}`
            )}
            {!envtype ? (
              <span className={`ml-3`} onClick={() => edit_env('doa_name')}>
                <Pencil />
              </span>
            ) : (
              ''
            )}
          </div>

          <div className={`ml-3 font-14`}>
            {type ? (
              <Row gutter={8}>
                <Col span={12}>
                  <Form form={form} layout="vertical">
                    <FormItem name="doa_description">
                      <Input
                        placeholder="Enter description"
                        onBlur={(e) =>
                          handleEdit(e.target.value, 'doa_description')
                        }
                        autoFocus
                      />
                    </FormItem>
                  </Form>
                </Col>
              </Row>
            ) : (
              `${localStorage.getItem('doa_description')}`
            )}
            {!type ? (
              <span
                className={`ml-3`}
                onClick={() => edit_env('doa_description')}
              >
                <Pencil />
              </span>
            ) : (
              ''
            )}
          </div>
          {/* <div className={`ml-3 font-14 `}>
            {projectId ? (
              <Row gutter={8}>
                <Col span={10}>
                  <Form form={form} layout="vertical">
                    <FormItem name="project_id">
                      <Select
                        showSearch
                        // onChange={(e) =>
                        //   handleEdit(e.target.value, 'project_id')
                        // }
                        onSelect={(value) => handleEdit(value, 'project_id')}
                        placeholder="Select a person"
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                      >
                        {projects.map((item, index) => (
                          <Option value={item.id} key={index}>
                            {item.title}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>
                  </Form>
                </Col>
              </Row>
            ) : (
              projects.map((item) =>
                item.id == `${localStorage.getItem('project_id')}`
                  ? `${item.title}`
                  : ''
              )
            )}
            {!projectId ? (
              <span className={`ml-3`} onClick={() => edit_env('project_id')}>
                <Pencil />
              </span>
            ) : (
              ''
            )}
          </div> */}
        </Col>

        <Col className={`text-right `} span={8}>
          {/* <CustomButton className={`mr-2`}>Advanced options</CustomButton> */}
          <CustomButton type="primary" className={`mr-2`} onClick={handleSave}>
            {' '}
            {'Save & Close'}
          </CustomButton>
          {/* <CustomButton type="primary">Next</CustomButton> */}
        </Col>
      </Row>
    </div>
  );
};

export default AdminHeader;
