import React from 'react';
import SetPasswordPage from './components/';

const index = () => {
  return (
    <>
      <SetPasswordPage />
    </>
  );
};

export default index;
