import Layout from '@/common/Layout';
import React from 'react';
import PendingManagePage from './components';

const Pending = () => {
  return (
    <>
      <Layout sider={true} tracker={true}>
        <PendingManagePage />
        {/* <h1> Testing Page for Pending status</h1> */}
      </Layout>
    </>
  );
};

export default Pending;
