import apiClient from 'utilities/apiClient';

export const stats = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.create_envelop}/${url}`,
    payload,
    true
  );
};

export const get_AllStandardEnvelopes = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.all_standard_envelopes}/${url}`,
    payload,
    true
  );
};

export const get_AllCustomEnvelopes = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.all_standard_envelopes}/${url}`,
    payload,
    true
  );
};

export const subcategories = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.subcategories}/${url}`, payload, true);
};

export const price = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.price}/${url}`, payload, true);
};

export const categories = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.categories}/${url}`, payload, true);
};

export const standardDoa = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.all_actions_required}/${url}`,
    payload,
    true
  );
};

export const projects = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.admin}/${url}`, payload, true);
};

export const businessunits = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.unit}/${url}`, payload, true);
};
