const All = () => {
  return (
    <svg
      width="14.17"
      height="14.17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.66602 15.5846L1.66602 9.7513L15.8327 9.7513V15.5846H1.66602Z"
        stroke="#8E8EA9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.66602 7.2513L1.66602 1.41797L15.8327 1.41797V7.2513L1.66602 7.2513Z"
        stroke="#8E8EA9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default All;
