import React, { useEffect, useState } from 'react';
import { Col, Row, Layout, Card, Modal, Spin, Tooltip, Table, Tag } from 'antd';

import CustomButton from '@/common/CustomButton';
import Close from '../../images/Closesvg';
// import Pencil from '../../images/Pencilsvg';
import styles from './index.module.less';
// import { useNavigate } from 'react-router-dom';

// import Draggable from 'react-draggable';
import { fileToBase64 } from 'utilities/helpers';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
// import LocalStorage from 'utilities/localStorage';
import logo from '../../../login/images/granules_logo.png';
// '../../images/granules_logo.png';
const InititateEnvelopePage2 = ({
  current,
  setCurrent,
  mergedPdfBlob,
  selectedOption,
  handleFormSubmit,
  initiate,
  env_type,
  plants,
  category,
  activity,
  subcategory,
  // locations,
  functions
}) => {
  // Get the value after "documents/"
  // const docUrl = 'https://tataproject.blob.core.windows.net/documents';

  // const extractedValue = valueAfterDocuments || '1.pdf';
  const [mergedPdfBlobToBase64, setMergedPdfBlobToBase64] = useState('');
  const [numPages, setNumPages] = useState(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    convertMergedPdfBlobToBase64();
  }, [mergedPdfBlob]);

  const convertMergedPdfBlobToBase64 = async () => {
    let result = await fileToBase64(mergedPdfBlob);

    setMergedPdfBlobToBase64(result);
  };

  // const navigate = useNavigate();

  const handleOnclickPrev = () => {
    setCurrent(current - 1);
  };

  // const user = LocalStorage.getItem('gnfa_user');

  // const title = localStorage.getItem('title');
  // const location = localStorage.getItem('location');
  // const functionn = localStorage.getItem('function');
  // const value = localStorage.getItem('value');
  // const budgetType = localStorage.getItem('budgetType');
  // const purchaseType = localStorage.getItem('purchaseType');

  // const storedLocation = localStorage.getItem('location');
  // const foundLocation = locations.find(
  //   (location) => location.id === parseInt(storedLocation, 10)
  // );
  // const storedFunction = localStorage.getItem('function');
  // const foundFunction = functions.find(
  //   (funct) => funct.id === parseInt(storedLocation, 10)
  // );
  // console.log('foundFunction', foundFunction?.function_name);
  // const combine_name = `${foundLocation?.location_name} -${foundFunction?.function_name}-${budgetType}-${purchaseType}-${value}`;
  // console.log('combine_name', combine_name);
  console.log('initiateenv_type', env_type);
  const Header = () => {
    return (
      <>
        <div className={`w-100 ${styles.init_env_header}`}>
          <Row>
            <div className={`df-jc-ac ${styles.x_button}`}>
              <Close />
            </div>
            <Col xs={24} xl={15} span={15}>
              <div className={`ml-3 font-18 `}>
                {/* {`${localStorage.getItem('title')}`} */}
                {env_type !== 'standard' && env_type !== 'custom'
                  ? localStorage.getItem('title')
                  : localStorage.getItem('envelope_name')}
                {/* <span className={`ml-3`}>
                  <Pencil />
                </span> */}
              </div>
              <div className={`ml-3 font-14`}>
                {/* {`${localStorage.getItem('description')}`} */}
                {/* {combine_name} */}
                {/* <span className={`ml-3`}>
                  <Pencil />
                </span> */}
              </div>
            </Col>

            <Col className={`text-right `} xs={24} xl={8} span={8}>
              <CustomButton
                className={`mr-2`}
                onClick={() => handleOnclickPrev()}
              >
                {' '}
                Go back
              </CustomButton>
              <CustomButton
                onClick={() => handleFormSubmit()}
                type="primary"
                loading={initiate}
              >
                Initiate
              </CustomButton>
            </Col>
          </Row>
        </div>
      </>
    );
  };

  // const summaryData = [
  //   { label: 'Plant', value: 'Corporate' },
  //   { label: 'Department', value: 'IT' },
  //   { label: 'I/O No', value: '1245' },
  //   { label: 'I/O Amount', value: '25000' },
  //   { label: 'NFA Amount', value: '100' },
  //   { label: 'Balance Amount', value: '900' },
  //   { label: 'Activity', value: 'Activity-1' },
  //   { label: 'Minimum Value', value: '0' },
  //   { label: 'Maximum Value', value: '500000' }
  // ];

  console.log('plants', plants);
  console.log('category', category);
  console.log('subcategory', subcategory);
  console.log('activity', activity);
  console.log('functions', functions);

  const plan_id = localStorage.getItem('plant_id');
  const plantName =
    plants.find((plant) => plant.id === Number(plan_id))?.name || 'N/A';

  const department = localStorage.getItem('department_id');
  const departmentName =
    functions.find((func) => func.id === Number(department))?.function_name ||
    'N/A';

  const nfaamount = localStorage.getItem('nfa_amount');
  const bamount = localStorage.getItem('balance_amount');
  const title = localStorage.getItem('title');
  const envelope_name = localStorage.getItem('envelope_name');
  console.log('env_type', env_type);

  const apiCallVal = JSON.parse(localStorage.getItem('apiCallValues')) || {};

  const api_plantName =
    plants.find((plant) => plant.id === Number(apiCallVal.plant_id))?.name ||
    'N/A';
  const api_departmentName =
    functions.find((func) => func.id === Number(apiCallVal.department_id))
      ?.function_name || 'N/A';

  const api_CategoryName =
    category.find((cate) => cate.id === Number(apiCallVal.category_id))
      ?.category_name || 'N/A';

  const api_SubcategoryName =
    subcategory.find(
      (subcate) => subcate.id === Number(apiCallVal.sub_category_id)
    )?.sub_category_name || 'N/A';

  const api_ActivityName =
    activity.find((activity) => activity.id === Number(apiCallVal.activity))
      ?.name || 'N/A';

  const summaryData = [
    ...(env_type !== 'standard' && env_type !== 'custom'
      ? [
          { key: '1', label: 'Plant', value: plantName },
          { key: '2', label: 'Department', value: departmentName },
          { key: '3', label: 'NFA Amount', value: nfaamount },
          { key: '4', label: 'Balance Amount', value: bamount },
          { key: '5', label: 'Title', value: title }
        ]
      : [
          { key: '1', label: 'Plant', value: api_plantName || 'N/A' },
          {
            key: '2',
            label: 'Department',
            value: api_departmentName || 'N/A'
          },
          {
            key: '3',
            label: 'Category',
            value: api_CategoryName || 'N/A'
          },
          {
            key: '4',
            label: 'Sub Category',
            value: api_SubcategoryName || 'N/A'
          },
          {
            key: '5',
            label: 'NFA Amount',
            value: apiCallVal.nfa_amount || 'N/A'
          },
          {
            key: '6',
            label: 'Balance Amount',
            value: apiCallVal.balance_amount || 'N/A'
          },
          { key: '7', label: 'Title', value: envelope_name || 'N/A' },
          { key: '8', label: 'I/O No', value: apiCallVal.io_no || 'N/A' },
          {
            key: '9',
            label: 'I/O Amount',
            value: apiCallVal.io_amount || 'N/A'
          },
          {
            key: '10',
            label: 'Cost Center',
            value: apiCallVal.cost_center || 'N/A'
          },
          { key: '11', label: 'Activity', value: api_ActivityName || 'N/A' }
        ])
  ];

  const modelstyles = {
    container: {
      padding: '16px'
    },
    summaryCard: {
      border: '1px solid #d9d9d9',
      borderRadius: '8px',
      overflow: 'hidden'
    },
    title: {
      fontSize: '18px',
      fontWeight: 'bold',
      color: '#0098da',
      textAlign: 'left'
      // marginLeft: '-505px'
    },
    headerLogo: {
      textAlign: 'right'
    },
    headerdesign: {
      textAlign: 'left'
    }
  };

  const columns = [
    {
      dataIndex: 'label',
      key: 'label',
      // width: '50%',
      // fontWeight: '500',
      render: (text) => text
      // render: (text) => (
      //   <div
      //     style={{
      //       fontWeight: 500,
      //       // padding: '8px 16px',
      //       backgroundColor: '#f5f5f5', // Change to desired color, e.g., 'red'
      //       borderRight: '1px solid #d9d9d9'
      //     }}
      //   >
      //     {text}
      //   </div>
      // )
    },
    {
      dataIndex: 'value',
      key: 'value',
      width: '50%'
    }
  ];

  const type = localStorage.getItem('type');

  return (
    <Layout className={`${styles.layout}`}>
      <Header />
      <div className={`${styles.container}`}>
        <Row>
          <Col className={` mr-3 text-left mt-3 `} xs={24} xl={17} span={17}>
            <Card
              className={` text-center ${styles.image_viewer_card}`}
              title={
                <div
                  className="df-jc-ac sfprotext-bold font-18"
                  style={{ color: '#0098DA' }}
                >
                  Preview your document below
                </div>
              }
              bordered={false}
            >
              {/* <div style={modelstyles.container}>
                <Row justify="space-between" align="middle">
                  <Col span={24}>
                    <Card
                      style={modelstyles.summaryCard}
                      title={
                        <span style={modelstyles.title}>Envelope Summary</span>
                      }
                    >
                      <Col span={6} style={modelstyles.headerLogo}>
                        <img
                          src={logo}
                          alt="Granules Logo"
                          style={{ width: '100px', height: 'auto' }}
                        />
                      </Col>
                      <Table
                        dataSource={summaryData}
                        columns={columns}
                        pagination={false}
                        showHeader={false}
                        rowClassName={() => 'summary-row'}
                      />
                    </Card>
                  </Col>
                </Row>
              </div> */}
              <div style={modelstyles.container}>
                <Row justify="space-between" align="middle">
                  <Col span={24}>
                    <Card
                      style={modelstyles.summaryCard}
                      title={
                        <Row justify="space-between" align="middle">
                          <Col span={18} style={modelstyles.headerdesign}>
                            <span style={modelstyles.title}>
                              Envelope Summary
                            </span>
                          </Col>
                          <Col span={6} style={modelstyles.headerLogo}>
                            <img
                              src={logo} // Replace with the correct path to your logo
                              alt="Granules Logo"
                              style={{ width: '100px', height: 'auto' }}
                            />
                          </Col>
                        </Row>
                      }
                    >
                      <Table
                        dataSource={summaryData}
                        columns={columns}
                        pagination={false}
                        bordered
                        showHeader={false}
                        rowClassName={() => 'summary-row'}
                      />
                    </Card>
                  </Col>
                </Row>
              </div>

              <Document
                file={mergedPdfBlobToBase64}
                onLoadSuccess={onDocumentLoadSuccess}
                className={`${styles.document_viewer}`}
              >
                {Array.from(new Array(numPages), (_, index) => (
                  <Page
                    width={window.innerWidth}
                    key={`page_${index + 1}`}
                    wrap={true}
                    pageNumber={index + 1}
                    className={`${styles.document_viewer_page}`}
                    size="A4"
                  />
                ))}
              </Document>
              {/* <React.Fragment>
                <div className={`${styles.imagesBox}`}>
                  <Draggable key={version}>
                    <div>
                      <img
                        style={{
                          transform: `scale(${scale}) rotate(${rotate}deg) 
                `
                        }}
                        draggable="false"
                        src="https://placekitten.com/400/400"
                      />
                    </div>
                  </Draggable>
                </div>
              </React.Fragment> */}
            </Card>
          </Col>

          <Col className={`pl-3 text-center mr-3 mt-3`} xs={24} xl={6} span={6}>
            <Card className={` text-left ${styles.env_flow}`}>
              <p className="font-18" style={{ color: '#0098DA' }}>
                <b>
                  Approval Flow
                  <Tag color="#0098DA" className="ml-3">
                    {/* {all_file_data?.type?.charAt(0).toUpperCase() +
                      all_file_data?.type?.slice(1) || '...'} */}
                    {type?.charAt(0).toUpperCase() + type?.slice(1) || '...'}
                  </Tag>
                </b>
              </p>
              <div className={`${styles.container1}`}>
                {selectedOption?.map((item, index) => (
                  <div className={`${styles.step} `} key={index}>
                    <div className={`${styles.v_stepper}`}>
                      <div className={`${styles.circle}`}>
                        <p>{index + 1}</p>
                      </div>
                      <div className={`${styles.line}`}></div>
                    </div>

                    <div className={`${styles.content}`}>
                      <b className="font-14">
                        <Tooltip placement="top" title={item?.name}>
                          {item?.name?.length > 30
                            ? item?.name
                                // .slice(0, item?.name?.indexOf('-'))
                                .substring(0, 30) + '...'
                            : item?.name}
                        </Tooltip>
                      </b>
                      <div className={`${styles.small_text} font-12`}>
                        Needs to sign
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </Card>
          </Col>
        </Row>
        <br />
      </div>
      <Modal visible={initiate} footer={false} closable={false}>
        <p style={{ textAlign: 'center' }}>Sending the document</p>
        <p style={{ textAlign: 'center' }}>
          <Spin />
        </p>
      </Modal>
    </Layout>
  );
};

export default InititateEnvelopePage2;
