import React from 'react';
import { Collapse } from 'antd';
import styles from './index.module.less';

const CustomCollapse = ({ className, children, ...rest }) => {
  return (
    <Collapse
      {...rest}
      className={` ${styles.collapse_container}
       ${className}`}
    >
      {children}
    </Collapse>
  );
};

export default CustomCollapse;
