const SideDelete = () => {
  return (
    <svg
      width="13.33"
      height="14.17"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.7507 3.5V13.5C12.7507 14.4205 12.0045 15.1667 11.084 15.1667H4.41732C3.49684 15.1667 2.75065 14.4205 2.75065 13.5V3.5M10.2507 3.5V2.66667C10.2507 1.74619 9.50446 1 8.58398 1H6.91732C5.99684 1 5.25065 1.74619 5.25065 2.66667V3.5M1.08398 3.5H14.4173"
        stroke="#8E8EA9"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default SideDelete;
