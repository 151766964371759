import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';

import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import React from 'react';
import styles from './index.module.less';

function CancelModal({
  modalVisible,
  setModalVisible,
  onFinishCancel,
  loading
}) {
  const [form] = Form.useForm();

  return (
    <CustomModal
      visible={modalVisible === 'cancel' ? true : false}
      className={styles.background}
      title={'Cancel the envelope'}
      onCancel={() => setModalVisible('')}
      /* width={'45%'} */
      footer={null}
    >
      <div className="">
        <Form
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
          onFinish={onFinishCancel}
        >
          <FormItem
            label="Comments"
            name="comments"
            rules={[
              {
                required: true,
                message: 'Please enter comments'
              }
            ]}
          >
            <TextArea
              style={{ width: '100%' }}
              placeholder="Add your reason for cancelling"
              rows={4}
              allowClear
            />
          </FormItem>

          <FormItem className="mb-1 text-right">
            <CustomButton
              className="mr-2"
              htmlType="button"
              onClick={() => setModalVisible('')}
            >
              Back
            </CustomButton>
            <CustomButton type="primary" htmlType="submit" loading={loading}>
              Cancel Envelope
            </CustomButton>
          </FormItem>
        </Form>
      </div>
    </CustomModal>
  );
}

export default CancelModal;
