import apiClient from '../../../utilities/apiClient';

export const allApprovers = (payload) => {
  return apiClient.get(`${apiClient.Urls.all_approvers}/all`, payload, true);
};

export const allProjects = (payload) => {
  return apiClient.get(`${apiClient.Urls.all_projects}`, payload, true);
};

export const allDoaFlows = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};

export const allDesignations = (payload) => {
  return apiClient.get(`${apiClient.Urls.all_designations}/all`, payload, true);
};

export const allSBUs = (payload) => {
  return apiClient.get(`${apiClient.Urls.all_sbu}/alllll`, payload, true);
};

export const allSBGs = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.all_sbg}/all`, payload, true);
};
// allUsers
export const allUsers = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};

export const allBUs = (payload) => {
  return apiClient.get(`${apiClient.Urls.unit}/all`, payload, true);
};

export const allDesignationsMappings = (payload) => {
  return apiClient.get(`${apiClient.Urls.all_mappings}/all`, payload, true);
};

export const approver_ById = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.all_approvers}/approval_role/${payload}`,
    payload,
    true
  );
};

export const designation_ById = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.all_designations}/designation/${payload}`,
    payload,
    true
  );
};

export const project_ById = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.admin}/projectby/${payload}`,
    payload,
    true
  );
};

export const designationMappings_ById = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.all_mappings}/mapping/${payload}`,
    payload,
    true
  );
};

export const SBU_ById = (payload) => {
  return apiClient.get(`${apiClient.Urls.all_sbu}/all`, payload, true);
};

export const BU_ById = (payload) => {
  return apiClient.get(`${apiClient.Urls.unit}/all`, payload, true);
};

export const SBG_ById = (payload) => {
  return apiClient.get(`${apiClient.Urls.all_sbg}/all`, payload, true);
};

export const create_Designations = (payload) => {
  return apiClient.post(
    `${apiClient.Urls.all_designations}/add`,
    payload,
    true
  );
};

export const create_DesignationMappings = (payload) => {
  return apiClient.post(`${apiClient.Urls.all_mappings}/add`, payload, true);
};

export const create_Approvers = (payload) => {
  return apiClient.post(`${apiClient.Urls.all_approvers}/add`, payload, true);
};

export const create_Projects = (payload) => {
  return apiClient.post(`${apiClient.Urls.admin}/add-project`, payload, true);
};

export const create_SBUs = (payload) => {
  return apiClient.post(`${apiClient.Urls.all_sbu}/add`, payload, true);
};

export const create_BUs = (payload) => {
  return apiClient.post(`${apiClient.Urls.unit}/add`, payload, true);
};

export const create_SBGs = (payload) => {
  return apiClient.post(`${apiClient.Urls.all_sbg}/add`, payload, true);
};

export const create_DOA_Flow = (payload) => {
  return apiClient.post(
    `${apiClient.Urls.all_standard_envelopes}/create-doa-flow`,
    payload,
    true
  );
};

export const search_Specifcity = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.create_envelop}/specificity_search`,
    payload,
    true
  );
};

export const filter_Designations = (payload) => {
  return apiClient.get(
    `${apiClient.Urls.create_envelop}/dsg_filter`,
    payload,
    true
  );
};
//

export const get_category = (payload) => {
  return apiClient.get(`${apiClient.Urls.categorylist}`, payload, true);
};

export const get_subcategory = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};

export const getplantss = (payload) => {
  return apiClient.get(`${apiClient.Urls.plants}`, payload, true);
};

export const getdepartments = (payload) => {
  return apiClient.get(`${apiClient.Urls.functions}`, payload, true);
};

export const get_activity = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
//get_plant_id
export const get_plant_id = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
// edit_plant_id
export const edit_plant_id = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
// create_plant
export const create_plant = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
// create_department
export const create_department = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
// edit_department
export const edit_department = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
// get_department_details
export const get_department_details = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
// get_activity_id
export const get_activity_id = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
// edit_activity
export const edit_activity = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
// create_activity
export const create_activity = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
export const categories_ById = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
// edit_categories
export const edit_categories = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
// create_Categories
export const create_Categories = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
// subcategories_ById
export const subcategories_ById = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
// edit_SubCategories
export const edit_SubCategories = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
// create_SubCategories
export const create_SubCategories = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
// userDetails_ById
export const userDetails_ById = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
// edit_userdetails
export const edit_userdetails = (url, payload) => {
  return apiClient.patch(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
// create_userdetails
export const create_userdetails = (url, payload) => {
  return apiClient.post(`${apiClient.Urls.masterlist}/${url}`, payload, true);
};
