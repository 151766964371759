import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { message } from 'antd';
import {
  envelopes,
  create_envelop,
  projects,
  likeenvelop,
  deleteenvelop
} from './api';

const initialState = {
  allEnvelopes: [],
  getAllEnvelopLoading: false,
  projects: []
};

const actions = {
  GET_ALL_ENVELOPES: 'getAllEnvelopes/GET_ALL_ENVELOPES',
  CREATE_ENVELOP: 'createEnvelop/CREATE_ENVELOP',
  GET_ALL_PROJECTS: 'getAllProjects/GET_ALL_PROJECTS',
  LIKE_ENVELOPE: 'likeEnvelope/LIKE_ENVELOPE',
  DELETE_ENVELOPE: 'deleteEnvelope/DELETE_ENVELOPE'
};

export const getAllEnvelopes = createAsyncThunk(
  actions.GET_ALL_ENVELOPES,
  async (payload) => {
    const response = await envelopes(`all-custom-standard`, payload);
    return response;
  }
);

export const createEnvelop = createAsyncThunk(
  actions.CREATE_ENVELOP,
  async (payload) => {
    const response = await create_envelop('add-envelope', payload);
    return response;
  }
);

export const getAllProjects = createAsyncThunk(
  actions.GET_ALL_PROJECTS,
  async (payload) => {
    let search = { search: payload };
    const response = await projects('all-projects', search);
    return response;
  }
);

export const likeEnvelope = createAsyncThunk(
  actions.LIKE_ENVELOPE,
  async (payload) => {
    const response = await likeenvelop(`add-fav`, payload);
    return response;
  }
);

export const deleteEnvelope = createAsyncThunk(
  actions.DELETE_ENVELOPE,
  async (payload) => {
    const response = await deleteenvelop(`delete-envelope`, payload);
    return response;
  }
);

export const adminSlice = createSlice({
  name: 'approvers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllEnvelopes.pending, (state) => {
        state.getAllEnvelopLoading = true;
      })
      .addCase(getAllEnvelopes.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        state.getAllEnvelopLoading = false;
        state.allEnvelopes = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          /*  message.error(msg); */
        }
      })
      .addCase(getAllEnvelopes.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvelopLoading = false;

        message.error(msg);
      });

    builder
      .addCase(createEnvelop.pending, (state) => {
        state.getAllEnvelopLoading = true;
      })
      .addCase(createEnvelop.fulfilled, (state, action) => {
        const { success } = action.payload;
        state.getAllEnvelopLoading = false;
        if (!success) {
          /*  message.error(msg); */
        }
      })
      .addCase(createEnvelop.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvelopLoading = false;

        message.error(msg);
      });

    builder
      .addCase(getAllProjects.pending, (state) => {
        state.getAllEnvelopLoading = true;
      })
      .addCase(getAllProjects.fulfilled, (state, action) => {
        const { success, data, pagination } = action.payload;
        state.getAllEnvelopLoading = false;
        state.projects = data ? data : [];
        state.pagination = pagination || null;

        if (!success) {
          /*  message.error(msg); */
        }
      })
      .addCase(getAllProjects.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvelopLoading = false;

        message.error(msg);
      });
    builder
      .addCase(likeEnvelope.pending, (state) => {
        state.getAllEnvelopLoading = true;
      })
      .addCase(likeEnvelope.fulfilled, (state, action) => {
        const { success, message: msg, pagination } = action.payload;
        state.getAllEnvelopLoading = false;
        state.pagination = pagination || null;

        if (!success) {
          /*  message.error(msg); */
        } else {
          message.success(msg);
        }
      })
      .addCase(likeEnvelope.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvelopLoading = false;

        message.error(msg);
      });
    builder
      .addCase(deleteEnvelope.pending, (state) => {
        state.getAllEnvelopLoading = true;
      })
      .addCase(deleteEnvelope.fulfilled, (state, action) => {
        const { success, pagination } = action.payload;
        state.getAllEnvelopLoading = false;
        state.pagination = pagination || null;

        if (!success) {
          /* message.error(msg); */
        }
      })
      .addCase(deleteEnvelope.rejected, (state, action) => {
        const { message: msg } = action.error;

        state.getAllEnvelopLoading = false;

        message.error(msg);
      });
  }
});
export default adminSlice.reducer;
