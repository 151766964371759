const Reports = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.00390625 10H8.00391V0H0.00390625V10ZM0.00390625 18H8.00391V12H0.00390625V18ZM10.0039 18H18.0039V8H10.0039V18ZM10.0039 0V6H18.0039V0H10.0039Z"
        fill="rgb(255 255 255 / 85%)"
      />
    </svg>
  );
};
export default Reports;
