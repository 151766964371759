import React, { useEffect, useMemo, useState } from 'react';
import { Card, Image, Space, Upload } from 'antd';
import CustomButton from '@/common/CustomButton';
import imageDataURLFromFile from 'utilities/imageDataURLfromFile';
import Delete from '../../images/Deletesvg';
import styles from './index.module.less';

const { Dragger } = Upload;
const { Meta } = Card;

const DisplayFiles = ({
  files,
  handleOnChangePdfUpload,
  handleOnDropPdfUpload,
  removeInputFields
}) => {
  const [filesLocal, setFilesLocal] = useState([]);
  const [uploading, setUploading] = useState(false);

  const memoizedFiles = useMemo(() => files, [files]);

  useEffect(() => {
    const temp = [];
    const arr = Array.from(memoizedFiles); // Use the memoized files
    const myFunction = async () => {
      setUploading(true);

      for (let i = 0; i < arr.length; i++) {
        const file = arr[i];
        const res = await imageDataURLFromFile(file);
        file.image = res.data;
        file.pages = res.pageCount;
        temp.push(file);
      }
      setFilesLocal(temp);
      setUploading(false);
    };

    myFunction();
  }, [memoizedFiles, removeInputFields]);

  return (
    <Space wrap>
      {filesLocal.map((item, index) => {
        return (
          <React.Fragment key={index}>
            <Card
              cover={
                <Image
                  preview={false}
                  alt={item.name}
                  src={item.image}
                  height={180}
                  style={{
                    border: '1px solid #f0f0f0'
                  }}
                />
              }
              style={{
                width: '240px'
              }}
            >
              <Meta
                key={index}
                title={item.name}
                description={`${item.pages} pages `}
              />

              <span
                className={`cursor-pointer ${styles.delete_button}`}
                onClick={(e) => removeInputFields(e, index)}
              >
                <Delete />
              </span>
            </Card>
          </React.Fragment>
        );
      })}
      <Dragger
        name="file"
        accept=".pdf"
        listType="picture-card"
        onChange={handleOnChangePdfUpload}
        onDrop={handleOnDropPdfUpload}
        customRequest={({ onSuccess }) => {
          setTimeout(() => {
            onSuccess('ok');
          }, 0);
        }}
        multiple={true}
        showUploadList={false}
      >
        <div className="p-3">
          <div>
            <span>Drag and drop your files here</span>
          </div>
          <div className="mb-3">
            <span>or</span>
          </div>
          <div>
            <CustomButton type="primary" loading={uploading}>
              Upload
            </CustomButton>
          </div>
        </div>
      </Dragger>
    </Space>
  );
};

export default DisplayFiles;
