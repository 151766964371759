// LoginPage.js
import CustomButton from '@/common/CustomButton';
import React from 'react';
import { Form, Input, Row, Col, Checkbox, Typography } from 'antd';
import styles from './index.module.less'; // Assuming you have a Less module for this component
// import { history } from 'app/history';
// import { useDispatch } from 'react-redux';
// import { useLocation } from 'react-router-dom';
// import LocalStorage from 'utilities/localStorage';
import logo from '../../images/granules_logo.png';
import FormItem from 'common/FormItem';
// import CustomText from '../../../../common/CustomText';
import { ArrowRightOutlined } from '@ant-design/icons';

const { Link } = Typography;
function LoginPage({ loading, onSubmit }) {
  const [form] = Form.useForm();

  // const user = LocalStorage.getItem('gnfa_user');
  // console.log('user', user);
  // useEffect(() => {
  //   localStorage.removeItem('gnfa_user');
  //   localStorage.clear();
  // }, []);

  const onFinish = (values) => {
    const { email, password } = values;

    let payload = {
      email,
      password
    };
    console.log('payload', payload);
    onSubmit(payload);
  };

  return (
    <div className={`${styles.fullContainer}`}>
      <div className={styles.leftSide}>
        <div className={styles.loginleft}>
          <h1>Manage your document workflows</h1>
          <p>
            Make your job easy by creating envelopes and sharing it across for
            approvals. Also its very easy to track the shared documents and
            create templates of your own.
          </p>
        </div>
      </div>

      {/* <div className={styles.rightSide}> */}

      <div className={styles.rightSide}>
        <div className={styles.container}>
          <div className={styles.logoContainer}>
            <img src={logo} alt="logo" />
          </div>
          <h1
            className={styles.formtitle}
            style={{
              color: '#0098DA'
            }}
          >
            Let&apos;s get started
          </h1>

          <div className={` ${styles.right_outer_container}`}>
            <Form
              form={form}
              layout="vertical"
              className={`ant-form ant-form-vertical mt-4 ${styles.form_width}`}
              onFinish={onFinish}
            >
              <FormItem
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
              >
                <Input
                  placeholder="Enter your email id"
                  className={styles.customLable}
                />
              </FormItem>
              <FormItem
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
              >
                <Input.Password
                  placeholder="Enter your password"
                  className={styles.customLable}
                />
              </FormItem>
              <Row gutter={24} className={styles.remember}>
                <Col span={12} xs={24} xl={12} sm={6} md={12}>
                  <Checkbox className=" sfprotext-medium font-12">
                    Remember
                  </Checkbox>
                </Col>
                <Col span={12} xs={24} xl={12} sm={6} md={12} className="p1-0">
                  <Link
                    href="/reset-password"
                    className="d-block text-right mb-4 sfprotext-medium font-12"
                    // className={styles.forgotpassword}
                  >
                    Forgot password?
                  </Link>
                </Col>
              </Row>
              <FormItem>
                <CustomButton
                  // block
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  className={styles.login_button}
                >
                  Sign in <ArrowRightOutlined />
                </CustomButton>
              </FormItem>
            </Form>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default LoginPage;
