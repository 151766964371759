import apiClient from '../../../utilities/apiClient';

export const allTrackers = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.filtercount}`, payload, true);
};

export const allData = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.dataList}`, payload, true);
};

export const historylist = (url) => {
  return apiClient.get(`${apiClient.Urls.actions}/${url}`, '', true);
};

export const resend = (url) => {
  return apiClient.get(`${apiClient.Urls.admin_tracker}/${url}`, '', true);
};
// allsearch
export const allsearch = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.actions}/${url}`, payload, true);
};
