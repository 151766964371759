// LoginPage.js
import CustomButton from '@/common/CustomButton';
import React from 'react';
import { Form, Input, Button } from 'antd';
import styles from './index.module.less'; // Assuming you have a Less module for this component
import { history } from 'app/history';
import { useDispatch } from 'react-redux';
// import { useLocation } from 'react-router-dom';
// import LocalStorage from 'utilities/localStorage';
import logo from '../../login/images/granules_logo.png';
import FormItem from 'common/FormItem';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { ResetPassword } from '../redux/slice';

// const { Link } = Typography;
function index({ loading }) {
  const [form] = Form.useForm();

  const onFinish = (values) => {
    const { email } = values;

    let payload = {
      email
    };
    console.log('payload', payload);
    onSubmit(payload);
  };

  const dispatch = useDispatch();

  const onSubmit = (payload) => {
    dispatch(ResetPassword(payload));
  };

  const backToLogin = () => {
    history.push('login');
  };

  return (
    <div className={`${styles.fullContainer}`}>
      <div className={styles.leftSide}>
        <div className={styles.loginleft}>
          <h1>Manage your document workflows</h1>
          <p>
            Make your job easy by creating envelopes and sharing it across for
            approvals. Also it`s very easy to track the shared documents and
            create templates of your own.
          </p>
        </div>
      </div>

      {/* <div className={styles.rightSide}> */}

      <div className={styles.rightSide}>
        <div className={styles.container}>
          <div className={styles.logoContainer}>
            <img src={logo} alt="logo" />
          </div>
          <div className={styles.formheader}>
            <h1
              className={styles.formtitle}
              style={{
                color: '#0098DA'
              }}
            >
              Forgot password
            </h1>
            <div className={styles.subcontent}>
              <p>
                {' '}
                {`Enter your email and we'll send you a link to reset your
                password`}
              </p>
            </div>
          </div>

          <div className={` ${styles.right_outer_container}`}>
            <Form
              form={form}
              layout="vertical"
              className={`ant-form ant-form-vertical mt-4 ${styles.form_width}`}
              onFinish={onFinish}
            >
              <FormItem
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Required field'
                  }
                ]}
              >
                <Input
                  placeholder="Enter your email"
                  className={styles.customLable}
                />
              </FormItem>

              <FormItem>
                <CustomButton
                  // block
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                  className={styles.login_button}
                >
                  Request reset link
                </CustomButton>
              </FormItem>
              <Form.Item>
                <Button
                  type="link"
                  onClick={backToLogin}
                  style={{ padding: 0 }}
                >
                  <ArrowLeftOutlined />
                  Back to login
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}

export default index;
