import React, { createElement } from 'react';
import styles from './index.module.less';
import SearchBar from '@/common/Content/searchBar';
import Header from 'common/Content/header';
import { Image, Card, Col, Menu, Row, Tooltip, Badge } from 'antd';
import emptyState from '../../images/empty.svg';
import CustomButton from 'common/CustomButton';
import AddTemplateModal from '../AddTemplateModal';
import {
  HeartFilled,
  HeartOutlined,
  DeleteOutlined,
  FileOutlined
} from '@ant-design/icons';
import UserGroup from '../../images/UserGroupsvg';
import moment from 'moment';
// import Heart from '../../images/Heartsvg';
// import HeartCheckbox from 'react-heart-checkbox';

const Dashboard = ({
  showAddTemplateModal,
  setShowAddTemplateModal,
  selectedStatus,
  setSelectedStatus,
  allEnvelopes,
  projects,
  setTypeValue,
  typeValue,
  handleDelete,
  handlelike,
  initiateEnv,
  setInitiateEnv,
  tempfileList,
  setTempFileList,
  setSearchproject,
  selectedProject,
  setSelectedProject
}) => {
  const statusesData = [
    { label: 'All', status: '' },
    { label: 'Standard', status: 'standard' },
    { label: 'Custom', status: 'custom' }
  ];

  const statusMenu = (
    <Menu>
      {statusesData.map((item, index) => (
        <Menu.Item
          key={index}
          onClick={() => {
            if (selectedStatus?.label === item.label) {
              setSelectedStatus(null);
            } else {
              setSelectedStatus(item);
            }
          }}
        >
          {item.label}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className={styles.MRS_container}>
      <Header
        justify="space-between"
        align="middle"
        title="My DoA Envelopes"
        buttons={[
          {
            type: 'primary',
            text: 'Create template',
            onClick: () => setShowAddTemplateModal(true)
          }
        ]}
      />
      <div>
        <SearchBar
          justify="start"
          align="middle"
          className="mt-4"
          gutter={32}
          inputProps={{
            placeholder: 'Search'
          }}
          filters={true}
          statusMenu={statusMenu}
          selectedStatus={selectedStatus}
        />
      </div>

      <div>
        {allEnvelopes?.length ? (
          <>
            <Row className="mt-4" gutter={[16, 16]}>
              {allEnvelopes?.map((item, index) => (
                <Col key={index} span={8}>
                  <Badge.Ribbon
                    text={
                      item.is_active
                        ? 'Deactivated'
                        : item.favourite
                        ? 'Favourite'
                        : ''
                    }
                    color="red"
                    className={
                      item.is_active
                        ? `${styles.badge_color}`
                        : item.favourite
                        ? `${styles.badge_fav_color}`
                        : `${styles.disabled_badge}`
                    }
                  >
                    <Card className={`${styles.template_card}`} hoverable>
                      <div className={`font-16 mb-2 ${styles.card_title}`}>
                        {item.title}
                      </div>
                      <div className=" mb-3 font-12">
                        <span>
                          <FileOutlined className={`${styles.icon_color}`} />
                          {'\u00a0'} {item.total_documents_count} formats
                        </span>
                        <span className="ml-4" style={{ marginLeft: '12px' }}>
                          <UserGroup />
                          {'\u00a0'} {item.total_approvers_count} approvers
                        </span>
                      </div>
                      <div className={` font-12 mb-3`}>
                        <div className={` font-12 ${styles.icon_color}`}>
                          {' '}
                          Type
                        </div>
                        <div className={` font-12 ${styles.icon_color}`}>
                          {item.envelope_type[0].toUpperCase() +
                            item.envelope_type.substring(1)}
                        </div>
                      </div>
                      <div className={` font-12 mb-3`}>
                        <div className={` font-12 ${styles.icon_color}`}>
                          {' '}
                          Owner
                        </div>
                        <div className={`font-12`}>{item?.created_by}</div>
                      </div>
                      <div className={` font-12 mb-3`}>
                        <div className={` font-12 ${styles.icon_color}`}>
                          Created on
                        </div>
                        <div className={`font-12`}>
                          {moment(item.createdAt).format('DD MMM YY')}
                        </div>
                      </div>
                      <div className={` font-12 `}>
                        <div className={` font-12 ${styles.icon_color_last}`}>
                          <span className="mr-3">
                            <DeleteOutlined
                              icon={<i className="mdi mdi-check" />}
                              onClick={() => handleDelete(item.id)}
                              className={
                                item.is_active
                                  ? `${styles.not_allowed}`
                                  : `${styles.allowed}`
                              }
                            />
                          </span>
                          <span>
                            <Tooltip key="comment-basic-like">
                              <span
                                onClick={() => handlelike(item.id)}
                                className={
                                  item.is_active
                                    ? `${styles.not_allowed}`
                                    : `${styles.allowed}`
                                }
                              >
                                {createElement(
                                  item.favourite === true
                                    ? HeartFilled
                                    : HeartOutlined
                                )}
                              </span>
                            </Tooltip>
                            {/* <HeartCheckbox checked={false} /> */}
                            {/* <Rate
                            onChange={() => handleFavourite(item.id)}
                            value={1}
                            // character={({ index }) => customIcons[index + 1]}
                          /> */}
                          </span>
                          <span className="pull-right">
                            {item.is_active ? (
                              <CustomButton
                                className={`${styles.use_button} mr-2`}
                                disabled
                              >
                                Use
                              </CustomButton>
                            ) : (
                              <CustomButton
                                className={`${styles.use_button} mr-2`}
                              >
                                Use
                              </CustomButton>
                            )}

                            {/* <CustomButton className={`${styles.down_button}`}>
                            <DownOutlined />
                          </CustomButton> */}
                          </span>
                        </div>
                      </div>
                    </Card>
                  </Badge.Ribbon>
                </Col>
              ))}
            </Row>
          </>
        ) : (
          <div className={styles.empty_state}>
            <span>
              <Image
                className={`mb-2 ${styles.empty_image}`}
                alt="survey_empty_state"
                src={emptyState}
                preview={false}
              />
              <div className={styles.empty_state_text}>
                No DoA envelopes created yet. Starting creating one
              </div>
              <CustomButton
                type="primary"
                className="mt-3"
                onClick={() => {
                  setShowAddTemplateModal(true);
                }}
              >
                Create DoA Envelope
              </CustomButton>
            </span>
          </div>
        )}
      </div>
      {showAddTemplateModal ? (
        <AddTemplateModal
          showAddTemplateModal={showAddTemplateModal}
          setShowAddTemplateModal={setShowAddTemplateModal}
          projects={projects}
          setTypeValue={setTypeValue}
          typeValue={typeValue}
          initiateEnv={initiateEnv}
          setInitiateEnv={setInitiateEnv}
          setTempFileList={setTempFileList}
          tempfileList={tempfileList}
          setSearchproject={setSearchproject}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
        />
      ) : null}
    </div>
  );
};

export default Dashboard;
