import CustomButton from '@/common/CustomButton';
import CustomModal from '@/common/CustomModal';
import FormItem from '@/common/FormItem';

import { Form } from 'antd';
import TextArea from 'antd/lib/input/TextArea';

import React from 'react';

import styles from './index.module.less';

function HoldModal({ modalVisible, setModalVisible, onFinish, loading }) {
  const [form] = Form.useForm();

  /* const dispatch = useDispatch(); */

  /* const handleReject = (envelope_id) => {
    const payload = { envelope_id: envelope_id, is_approved: true };
    dispatch(rejectEnvelope(payload)).then((response) => {
     
      if (response?.payload?.success === true) {
        history.push('/app/recomm-vcc');
      }
    });
  }; */

  return (
    <CustomModal
      visible={modalVisible === 'hold' ? true : false}
      className={styles.background}
      title={'Need Clarification to the document'}
      onCancel={() => setModalVisible('')}
      /*  width={'45%'} */
      footer={null}
    >
      <div className="">
        <Form
          form={form}
          layout="vertical"
          className="ant-form ant-form-vertical"
          onFinish={onFinish}
        >
          <FormItem
            label="Comments"
            name="comments"
            rules={[
              {
                required: true,
                message: 'Please enter comments'
              }
            ]}
          >
            <TextArea
              style={{ width: '100%' }}
              placeholder="Add your reason here and let others know..."
              rows={4}
              allowClear
            />
          </FormItem>

          <FormItem className="mb-1 text-right">
            <CustomButton
              className="mr-2"
              htmlType="button"
              onClick={() => setModalVisible('')}
            >
              Cancel
            </CustomButton>
            <CustomButton type="primary" htmlType="submit" loading={loading}>
              Need Clarification
            </CustomButton>
          </FormItem>
        </Form>
      </div>
    </CustomModal>
  );
}

export default HoldModal;
