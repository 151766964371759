const Delete = ({ className }) => {
  return (
    <svg
      className={`${className}`}
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.0146 3.69216V14.1796C13.0146 15.1449 12.232 15.9275 11.2667 15.9275H4.2751C3.30976 15.9275 2.5272 15.1449 2.5272 14.1796V3.69216M10.3927 3.69216V2.81821C10.3927 1.85287 9.61018 1.07031 8.64484 1.07031H6.89694C5.93161 1.07031 5.14904 1.85287 5.14904 2.81821V3.69216M0.779297 3.69216H14.7625M6.02299 7.18796V12.4317M9.51879 7.18796V12.4317"
        stroke="#32324D"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Delete;
