import apiClient from 'utilities/apiClient';

export const approvers = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.approvers}/${url}`, payload, true);
};

export const create_envelop = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.create_envelop}/${url}`,
    payload,
    true,
    'file'
  );
};

export const create_standard_envelop = (url, payload) => {
  return apiClient.post(
    `${apiClient.Urls.admin}/${url}`,
    payload,
    true,
    'file'
  );
};

export const get_AllStandardEnvelopes = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.all_standard_envelopes}/${url}`,
    payload,
    true
  );
};

export const projects = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.admin}/${url}`, payload, true);
};

export const customenv = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.all_standard_envelopes}/${url}`,
    payload,
    true
  );
};

export const allenv = (url, payload) => {
  return apiClient.get(
    `${apiClient.Urls.all_standard_envelopes}/${url}`,
    payload,
    true
  );
};

export const unit = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.unit}/${url}`, payload, true);
};

export const price = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.price}/${url}`, payload, true);
};

export const categories = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.categories}/${url}`, payload, true);
};

export const subcategories = (url, payload) => {
  return apiClient.get(`${apiClient.Urls.subcategories}/${url}`, payload, true);
};
