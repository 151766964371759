const Close = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.75 1.70857L11.5414 0.5L6.75 5.29143L1.95857 0.5L0.75 1.70857L5.54143 6.5L0.75 11.2914L1.95857 12.5L6.75 7.70857L11.5414 12.5L12.75 11.2914L7.95857 6.5L12.75 1.70857Z"
        fill="#212134"
      />
    </svg>
  );
};
export default Close;
