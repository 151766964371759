import Layout from '@/common/Layout';
import React from 'react';
import AdminManagePage from './components';

const AdminManage = () => {
  return (
    <>
      <Layout sider={true} tracker={true}>
        <AdminManagePage />
      </Layout>
    </>
  );
};

export default AdminManage;
